import Card from "../../components/Card/Card";
import Inquiry from "../Inquiry/Inquiry";
import React, { useRef } from "react";
import Articles from "../../components/Articles/Articles";

export default function About() {
  const contactUs = useRef(null);
  return (
    <>
      <Card
        backgroundColor={"#a0a0a2"}
        title={"We are Sterling"}
        hasContactBtn={true}
        list={[]}
        hasLearnMoreBtn={false}
        sideText={"This is us"}
        direction={"right"}
        image={"/we-are.png"}
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
        description={`Sterling Serviced Office Group is backed by individual and corporate investors from the United Kingdom and Hong Kong and led by Giles Blanchard, Managing Director and founder.
        Our focus is to provide the highest possible standards of office location, interior environments and premium business services. Based on over Seventeen years of successfully serving some of the biggest global companies, we are poised for significant expansion of the Sterling brand, specifically targeting emerging economies where global business is seeking out new markets.
        Our team is highly experienced, professional and passionate about the industry. First class service, hospitality, courtesy and flexibility are priority in our day to day operations.`}
      />
      {/* <Articles/> */}
      <div ref={contactUs}>
        <Inquiry backgroundColor={"#FFFFFF"} direction={"left"} city={"Sofia"}/>
      </div>
    </>
  );
}
