import React, { useState } from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { Button, Divider, Menu, MenuItem, useMediaQuery } from "@mui/material";
import MobileHeader from "../MobileHeader/MobileHeader";

function Header() {
  const [selectedPage, setSelectedPage] = useState(null);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [anchors, setAnchors] = useState({
    Location: null,
    Services: null,
  });
  const openLocation = Boolean(anchors.Location);
  const openServices = Boolean(anchors.Services);
  const [innerMenu,setInnerMenu] = useState("");
  const [innerLocations,setInnerLocations] = useState("");
  const handleClick = (event) => {
    setInnerMenu("");
    setInnerLocations("");
    setAnchors({
      ...anchors,
      [event.currentTarget.textContent]: event.currentTarget,
    });
  };
  const handleCloseLocation = () => {
    setInnerMenu("");
    setAnchors({ ...anchors, Location: null });
  };
  const handleCloseServices = () => {
    setInnerLocations("");
    setAnchors({ ...anchors, Services: null });
  };


  return Boolean(isMobile) ? (
    <MobileHeader />
  ) : (
    <div className="header-container">
      <NavLink to={"/"}>
        <img className="header-logo" src="/sterling-logo.png" alt="logo" onClick={()=>{setSelectedPage(null)}}/>
      </NavLink>
      <div className="navigation-list">
        <Button
          style={
            selectedPage === "location" ? { borderTop: "1px solid gray" } : {}
          }
          disableRipple
          variant="string"
          aria-haspopup="true"
          aria-expanded={openLocation ? "true" : undefined}
          onClick={handleClick}
        >
          {selectedPage === "location" ? <b>Location</b> : "Location"}
        </Button>
        <Menu
          anchorEl={anchors.Location}
          open={openLocation}
          onClose={handleCloseLocation}
        >
          <MenuItem
            onClick={() => {
              handleCloseLocation();
              setSelectedPage("location");
              setInnerLocations("Sofia")
            }}
          >
            <NavLink to={"/locations/sofia"}>{innerLocations === "Sofia" ? <b>STERLING SOFIA</b> : "STERLING SOFIA"}</NavLink>
          </MenuItem>
          <Divider style={{ margin: "0 30px" }} />
          <MenuItem
            onClick={() => {
              handleCloseLocation();
              setSelectedPage("location");
              setInnerLocations("Istanbul")
            }}
          >
            <NavLink to={"/locations/istanbul"}>{innerLocations === "Istanbul" ? <b>STERLING ISTANBUL</b> : "STERLING ISTANBUL"}</NavLink>
          </MenuItem>
        </Menu>
        <Button
          style={
            selectedPage === "services" ? { borderTop: "1px solid gray" } : {}
          }
          disableRipple
          variant="string"
          aria-haspopup="true"
          aria-expanded={openServices ? "true" : undefined}
          onClick={handleClick}
        >
          {selectedPage === "services" ? <b>Services</b> : "Services"}
        </Button>
        <Menu
          anchorEl={anchors.Services}
          open={openServices}
          onClick={() => {
            handleCloseServices();
            setSelectedPage("services");
          }}
        >
          <MenuItem onClick={()=>{
            handleCloseServices();
            setInnerMenu("serviced");
            }}>
            <NavLink to={"/serviced-offices"}>{innerMenu === "serviced" ? <b>SERVICED OFFICES</b> : "SERVICED OFFICES"}</NavLink>
          </MenuItem>
          <Divider style={{ margin: "0 30px" }} />
          <MenuItem onClick={handleCloseServices}>
            <NavLink to={"/workspace-solutions"}>
              FLEXIBLE WORKSPACE SOLUTIONS
            </NavLink>
          </MenuItem>
          <Divider style={{ margin: "0 30px" }} />
          <MenuItem onClick={handleCloseServices}>
            <NavLink to={"/meeting"}>MEETING {"&"} CONFERENCE ROOMS</NavLink>
          </MenuItem>
          <Divider style={{ margin: "0 30px" }} />
          <MenuItem onClick={handleCloseServices}>
            <NavLink to={"/business-services"}>BUSINESS SERVICES</NavLink>
          </MenuItem>
        </Menu>
        <NavLink
          style={selectedPage === "why" ? { borderTop: "1px solid gray" } : {}}
          onClick={() => {
            setSelectedPage("why");
          }}
          to={"/why"}
        >
          <p>{selectedPage === "why" ? <b>Why Sterling</b> : "Why Sterling"}</p>
        </NavLink>
        <NavLink
          style={
            selectedPage === "about" ? { borderTop: "1px solid gray" } : {}
          }
          onClick={() => {
            setSelectedPage("about");
          }}
          to={"/about"}
        >
          <p>{selectedPage === "about" ? <b>About Sterling</b> : "About Sterling"}</p>
        </NavLink>
      </div>
    </div>
  );
}

export default Header;
