import React from "react";
import { Typography } from "@mui/material";
import styles from "./CarouselCard.module.scss";

export default function CarouselCard({ image, title, listItems,different }) {
  return (
  <>
    {different > 0  ? 
    <div className={styles.container} style={{height:`${33}%`}}>
    <div className={styles["carousel-content"]}>
      <img src={image} alt="reception" />
      <div className={styles.info}>
        <Typography variant="h6">{title}</Typography>
        <ul className={styles.list}>
          {listItems.map((item, index) => (
            <li className={`${styles["list-item"]}`} key={index}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
    : <div className={styles.container}>
      <div className={styles["carousel-content"]}>
        <img src={image} alt="reception" />
        <div className={styles.info}>
          <Typography variant="h6">{title}</Typography>
          <ul className={styles.list}>
            {listItems.map((item, index) => (
              <li className={`${styles["list-item"]}`} key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
}
  </>
  );
}
