import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useMediaQuery } from "@mui/material";
import { Grid, Tab } from "@mui/material";
import styles from "./Tabs.module.scss";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  tab: {
    color: "black",
    "&.Mui-selected": {
      color: "#1976d2",
    },
  },
}));

export default function TabsComponent({
  sofiaText,
  istanbulText,
  contactUsHref,
  backgroundColor,
}) {
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return Boolean(isMobile) ? (
    <Grid
      container
      direction={"column"}
      alignItems="center"
      justifyContent={"flex-start"}
      style={{
        backgroundColor: backgroundColor,
        color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
      }}
      className={styles[`tabs-container-mobile`]}
    >
      
      <h3 className={styles.heading}>BENEFITS</h3>
      <div className={styles[`horizontal-text-container`]}>
        <p style={{fontSize:"18px",textTransform:"uppercase"}}>This is us</p>
      </div>
      <Grid item xs={12}>
        <img
          className={styles["image-container"]}
          src={"/sterling-sofia.png"}
          alt="img"
        />
      </Grid>
      <Grid item xs={12} style={{width:"100%"}}>
        <p className={styles.title} style={{textAlign:"left"}}>Sterling Sofia</p>
      </Grid>
      <Grid item xs={12} className={styles["info-container"]}>
        <div
          dangerouslySetInnerHTML={{ __html: sofiaText }}
          className={styles.description}
        ></div>
      </Grid>
      <Grid item xs={12}>
        <img
          className={styles["image-container"]}
          src={"/sterling-istanbul.png"}
          alt="img"
        />
      </Grid>
      <Grid item xs={12}  style={{width:"100%"}}>
        <p className={styles.title} style={{textAlign:"left"}}>Sterling Istanbul</p>
      </Grid>
      <Grid item xs={12} className={styles["info-container"]}>
        <div
          dangerouslySetInnerHTML={{ __html: istanbulText }}
          className={styles.description}
        ></div>
        <div className={styles["buttons-container"]}>
          <a className={styles[`contact-btn`]} onClick={() => contactUsHref()}>
            CONTACT US
          </a>
        </div>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      style={{
        backgroundColor: backgroundColor,
        color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
      }}
      className={styles["home-container"]}
      alignItems="center"
    >
      <Grid item xs={6} className={styles["image-container"]} style={{height:"100vh"}}>
        <img
          src={value === "1" ? "/sterling-sofia.png" : "/sterling-istanbul.png"}
          alt="img"
          style={{height:"100vh"}}
        />
      </Grid>
      <Grid item xs={6} className={styles["info-container"]}>
        <h2 className={styles.title} style={{paddingTop:"23px"}}>TOP LOCATIONS</h2>
        <h2 className={styles.innerTitle}>Everything you need</h2>
        <div className={styles.description}>
          <TabContext value={value} style={{display:window.innerWidth < 1400 && "none"}}>
            <TabList
              TabIndicatorProps={{
                style: {
                  top: "0px",
                },
              }}
              onChange={handleChange}
            >
              <Tab
                classes={tabClasses}
                label="Sterling Sofia"
                value="1"
              />
              <Tab classes={tabClasses} label="Sterling Istanbul" value="2" />
            </TabList>
            <TabPanel value="1" style={{padding:"0",marginTop:"5%",lineHeight:"1.8em"}}>{sofiaText}</TabPanel>
            <TabPanel value="2" style={{padding:"0",marginTop:"5%",lineHeight:"1.8em"}}>{istanbulText}</TabPanel>
          </TabContext>
        </div>
        <div className={styles["buttons-container"]}>
          <a
            style={{ color: "#FFFFFF"}}
            className={styles[`contact-btn`]}
            onClick={() => contactUsHref()}
          >
            CONTACT US
          </a>
        </div>
      </Grid>
    </Grid>
  );
}
