import React, { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import styles from "./Benefits.module.scss";
import { NavLink } from "react-router-dom";
import MobileBenefits from "../MobileBenefits/MobileBenefits";

const benefits = [
  {
    title: "Unique business environment",
    description:
      "We host multinational companies, leaders in their sectors, who value the unique Sterling business environment. We choose high quality to ensure pleasant and productive atmosphere for our clients.  We focus on wellbeing, environmentally friendly and sustainable workspace environment.",
  },
  {
    title: "First Class Services",
    description:
      "With over 18 years in the industry, we provide the best you can get - great image, convenient and productive environment, premium Business Services to support your success.",
  },
  {
    title: "Tailored",
    description:
      "We know every business has specific needs that is why at Sterling we offer a tailored package on office type and equipment as well as Business Services, including PA and IT support.",
  },
  {
    title: "Cost effective",
    description:
      "Zero capital expenditure. No set-up costs - everything is in place - You just bring your computer and start working. Our inclusive pricing policy allows you control costs and pay 2 invoices instead of 20 each month.",
  },
  {
    title: "Flexible",
    description:
      "Meeting Clients' needs as to length of term, size and specific business requirements. As your office requirements change, we will try to adapt your workplace solution to your evolving needs.",
  },
  {
    title: "Immediate",
    description:
      "Save time and resources looking for the right location, type of office, fit-out, negotiating with suppliers, etc. You can move in and be fully operational in a matter of hours.",
  },
  {
    title: "Best Locations",
    description:
      "Sterling locations are second to none - prestigious and convenient. Right in the heart of city, in the business district and in vicinity of everything",
  },
  {
    title: "Full Range of Facilities",
    description:
      "Sterling clients benefit from all shared facilities such as: Reception and Welcoming Area, variety of Meeting and Conference Rooms, Client Services Area, Café, and Club Lounge.",
  },
  {
    title: "State-of-art technology",
    description:
      "Fast and reliable Internet, immediate access to Telecom system with dedicated lines, phone answering, call forwarding, voice mail. Audio and Videoconference facilities available in the meeting rooms.",
  },
  {
    title: "Premium fit out and design",
    description:
      "We pay attention to every detail – from the exclusive design, top quality furniture and latest IT technology, to Hefele electronic key system, rich taste of Italian coffee and great office view - all a premium experience.",
  },
  {
    title: "Sterling support",
    description:
      "Running your business from a Sterling office or executive suite couldn’t be easier – you concentrate on your business we take care of the rest.",
  },
  {
    title: "Non-branded!",
    description:
      "Our centers are non-branded – providing the best corporate image and identity for Your Company! It is Your Office!",
  },
];

export default function Benefits({ contactUs }) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [currentBenefit, setCurrentBenefit] = useState(benefits[0]);
  return Boolean(isMobile) ? (
    <MobileBenefits benefits={benefits} />
  ) : (
    <Grid
      container
      alignItems={"center"}
      className={styles["benefits-container"]}
    >
      <Grid container item xs={6} height={"100%"}>
        <Grid
          container
          spacing={3}
          justify="center"
          className={styles["benefits-indexes-container"]}
        >
          {benefits.map((benefit, index) => (
            <Grid
              onClick={() => setCurrentBenefit(benefit)}
              item
              xs={3}
              align="center"
              key={index}
              className={`${styles.benefit} ${
                currentBenefit === benefit && styles.active
              }`}
            >
              <div className={styles.index}>{ index + 1 === 12 ? index + 1 : index + 1}</div>
              <p className={styles["benefit-title"]}>{benefit.title}</p>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={6} className={styles["info-container"]}>
        <h2 className={styles.title}>BENEFITS</h2>
        <h4 className={styles.title} style={{marginTop:"-9%",marginBottom:"-4%"}}>{currentBenefit.title}</h4>
        <p className={styles.description} style={{width:"59vw"}}>{currentBenefit.description}</p>
        <div className={styles["buttons-container"]}>
          <a
            className={`${styles[`contact-btn`]}`}
            onClick={() =>
              contactUs.current.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
              })
            }
          >
            CONTACT US
          </a>
        </div>
      </Grid>
    </Grid>
  );
}
