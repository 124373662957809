import React from "react";
import Card from "../../components/Card/Card";

export default function HomeCards({ contactUs }) {
  const cards = [
    {
      direction: "right",
      image: "/home-page-1.png",
      title: "Sterling",
      description:
        "Our focus is to provide the highest possible standards of flexible workspace, office location, interior environments and premium business services.",
      sideText: "Your long term office solution",
      backgroundColor: "#FFFFFF",
      learnMoreHref: "/about",
    },
    {
      direction: "left",
      backgroundColor: "#A0A0A2",
      image: "/office.png",
      title: "Serviced Offices",
      description: `As each business is different with different needs, we tailor each
        office and services package according to individual requirements.
        We host all types and size of mainly international businesses and
        corporations from different business sectors.`,
      sideText: "Diverse customer base",
      learnMoreHref: "/serviced-offices",
    },
    {
      direction: "right",
      image: "/home-page-3.png",
      backgroundColor: "#FFFFFF",
      title: "Flexible Workspace Solutions",
      description: `If you’re a start-up business, an established company investigating
      a new market, or an international organisation looking for a local
      base, a virtual office is the ideal, low cost starter solution. It has all
      the benefits of a serviced office, just without the space. It’s a
      prestigious business address with a full range of business support 
      services, so your corporate presence is felt even if you are not
      there.`,
      sideText: "Understanding business and business needs",
      learnMoreHref: "/workspace-solutions",
    },
    {
      direction: "left",
      image: "/meeting-room.png",
      backgroundColor: "#A0A0A2",
      title: "Meeting & Conference Rooms",
      description: `Whether it’s a Seminar, an Interview, a Board meeting, a 
        Negotiation, a Cocktail or a Videoconference, we are here to 
        support you to present your business the most professional way.`,
      sideText: "Your perfect choice",
      learnMoreHref: "/meeting",
    },
    {
      direction: "right",
      image: "/key-advantages.png",
      title: "Business Services",
      backgroundColor: "#FFFFFF",
      description: `We’ve created the exclusive Silver Rewards programme, free for all
      our office and virtual office clients so you can benefit from the
      discounts and special offers arranged with our partners. These
      benefits include discounts at restaurants, shops, hotels, travel
      agents, car hire and many other services.`,
      sideText: "Experience the very best",
      learnMoreHref: "/business-services",
    },
  ];
  return (
    <>
      {cards.map((card, index) => (
        <Card
          direction={card.direction}
          description={card.description}
          title={card.title}
          image={card.image}
          sideText={card.sideText}
          list={[]}
          key={index}
          hasContactBtn={true}
          hasLearnMoreBtn={true}
          backgroundColor={card.backgroundColor}
          contactUsHref={() =>
            contactUs.current.scrollIntoView({
              block: "center",
              inline: "center",
              behavior: "smooth",
            })
          }
          learnMoreHref={card.learnMoreHref}
        />
      ))}
    </>
  );
}
