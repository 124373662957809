import { Grid } from "@mui/material";
import React from "react";
import styles from "./MobileBenefits.module.scss";

export default function MobileBenefits({ benefits }) {
  return (
    <div className={styles["benefits-component-container"]}>
      
      <h3 className={styles.title}>BENEFITS</h3>
      <div
        className={`
              ${styles[`side-text-container`]}`}
      >
        <p style={{fontSize:"18px"}}>WHY STERLING</p>
      </div>
      <Grid
        container
        direction={"column"}
        className={styles["benefit-container"]}
      >
        {benefits.map((benefit, index) => (
          <>
            <Grid item xs={5} className={styles["title-container"]}>
              <div className={styles.index}>{index + 1}</div>
              <p className={styles["benefit-title"]}>{benefit.title}</p>
            </Grid>
            <Grid item className={styles["description-container"]}>
              <p className={styles.description}>{benefit.description}</p>
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
}
