import AppBar from "@mui/material/AppBar";
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import { Drawer, IconButton, List, ListItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.scss";
import { NavLink } from "react-router-dom";

const listItems = [
  {
    text: "<b>Home</b>",
    href: "/",
  },
  {
    text: "<b>Locations</b>",
  },
  {
    text: "Sterling Sofia",
    href: "/locations/sofia",
  },
  {
    text: "Sterling Istanbul",
    href: "/locations/istanbul",
  },
  {
    text: "<b>Services</b>",
  },
  {
    text: "Meeting & Conference Rooms",
    href: "/meeting",
  },
  {
    text: "Serviced Offices",
    href: "/serviced-offices",
  },
  {
    text: "FLEXIBLE WORKSPACE SOLUTIONS",
    href: "/workspace-solutions",
  },
  {
    text: "BUSINESS SERVICES",
    href: "/business-services",
  },
  {
    text: "<b>Why Sterling</b>",
    href: "/why",
  },
  {
    text: "<b>About Sterling</b>",
    href: "/about",
  },
];

export default function MobileHeader() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentPage, setCurrentPage] = useState("<b>Home</b>");
  const handleOpenNavigationDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseNavigationDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <AppBar position="static" style={{ background: "#FFFFFF" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleOpenNavigationDrawer}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <div className={"content-wrapper"}>
            <NavLink to={"/"}>
            <img
              className={"logo"}
              src="/sterling-logo.png"
              alt="sterling-logo"
            />
            </NavLink>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: {
            maxHeight: "684px",
          },
        }}
        className={"drawer"}
        open={openDrawer}
        anchor={"top"}
        onClose={handleCloseNavigationDrawer}
      >
        <List>
          <ListItem button onClick={() => handleCloseNavigationDrawer()}>
            <CloseIcon />
          </ListItem>
          {listItems.map((item, index) => (
            <ListItem
              className={"listItem"}
              button
              onClick={() => {
                handleCloseNavigationDrawer();
              }}
            >
              <ListItem>
                {item.href ? (
                  <NavLink
                    style={{
                      borderBottom:
                        currentPage === item.text ? "1px solid black" : "none",
                    }}
                    to={item.href}
                    onClick={() => setCurrentPage(item.text)}
                  >
                    <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                  </NavLink>
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                )}
              </ListItem>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
