import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import Home from "../../screens/Home/Home";
import MeetingConferenceRooms from "../../screens/MeetingConferenceRooms/MeetingConferenceRooms";
import ServicedOffices from "../../screens/ServicedOffices/ServicedOffices";
import FlexibleWorkspaceSolutions from "../../screens/FlexibleWorkspaceSolutions/FlexibleWorkspaceSolutions";
import BusinessServices from "../../screens/BusinessServices/BusinessServices";
import IstanbulLocation from "../../screens/Locations/Istanbul/Istanbul";
import SofiaLocation from "../../screens/Locations/Sofia/Sofia";
import Why from "../../screens/Why/Why";
import About from "../../screens/About/About";
import ArticlePage from "../../screens/Article/ArticlePage";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cookies: false,
    };
  }
  componentDidMount() {
    const agreedToCookies = localStorage.getItem("agreeToCookies");
    if (agreedToCookies) {
      this.setState({ cookies: true });
    } else {
      this.setState({ cookies: false });
    }
    document.body.style.overflowY = "hidden";
    setTimeout(() => {
      this.setState({ loading: false });
      if (!agreedToCookies) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "scroll";
      }
    }, 500);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cookies) {
      document.body.style.overflowY = "scroll";
    }
  }
  render() {
    return (
      <div className="dashboard-wrap">
        {!this.state.cookies && (
          <div
            className="cookies-overlay"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              zIndex: "9999",
              backgroundColor: "rgba(0, 0, 0, 0.450)",
            }}
          >
            <div
              className="cookies-container"
              style={
                window.innerWidth < 900
                  ? {
                      width: "100vw",
                      height: "200px",
                      position: "absolute",
                      bottom: "3%",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 20px 40px 20px",
                      fontSize: "12px",
                    }
                  : {
                      width: "100vw",
                      height: "150px",
                      position: "absolute",
                      bottom: "0",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 40px",
                    }
              }
            >
              <p style={{ width: "80%" }}>
                We use cookies in order to improve the navigation, the
                performance and the functionality of our website. For more
                information regarding the cookies, please read our
                <p
                  style={{
                    display: "inline",
                    margin: "0 6px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("/files/Cookies.pdf")
                  }
                >
                  Cookies Policy.
                </p>
                By using our website you agree with our
                <p
                  style={{
                    display: "inline",
                    margin: "0 6px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("/files/Cookies.pdf")
                  }
                >
                  Cookies Policy.
                </p>
              </p>
              <div
                className="flex-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem("agreeToCookies", true);
                  this.setState({ cookies: true });
                  document.body.style.overflowY = "scroll";
                }}
              >
                <p>ACCEPT</p>
                <div
                  className="arrow-btn"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                    backgroundImage: "url(/icons/arrow-right-black.svg)",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "9999",
            backgroundColor: "black",
            opacity: this.state.loading ? "1" : "0",
            display: this.state.loading ? "flex" : "none",
          }}
        >
        </div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/en" component={Home}/>
          <Route exact path="/meeting" component={MeetingConferenceRooms} />
          <Route exact path="/serviced-offices" component={ServicedOffices} />
          <Route
            exact
            path="/workspace-solutions"
            component={FlexibleWorkspaceSolutions}
          />
          <Route exact path="/business-services" component={BusinessServices} />
          <Route
            exact
            path="/locations/istanbul"
            component={IstanbulLocation}
          />
          <Route exact path="/locations/sofia" component={SofiaLocation} />
          <Route exact path="/why" component={Why} />
          <Route exact path="/about" component={About} />
          <Route exact path="/article" component={ArticlePage} />
        </Switch>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return { loading: state.starter.loading };
// };

export default withRouter(Routes);
