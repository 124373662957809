import React, { useRef } from "react";

import Card from "../../components/Card/Card";
import Cover from "../../components/Cover/Cover";
import KeyAdvantages from "../../components/KeyAdvantages/KeyAdvantages";
import Inquiry from "../Inquiry/Inquiry";
import ListCard from "../../components/ListCard/ListCard";


const listItems = [
  "Select from a range of meeting rooms set up for conference, seminar, interview or board meetings",
  "Book on hourly / daily or monthly basis",
  "Select the equipment and services you require",
  "We help you organize your event , catering,  coffee breaks and help your meetings be a success"
]
const secondListItems = [
  "Meeting and Board rooms range from 6-8-12 persons",
  "Seminar and Training rooms range from 8-12 persons",
  "Presentation and Negotiation rooms range from 6-8-12 person",
  "Sterling Reception and Welcoming area on floor 8, 9 & 10",
  "Cafe area",
]
const thirdListItems = [
  "Meeting and Board rooms range from 8-10-12-14-16-24 persons",
  "Seminar and Training rooms range from 8-18-22-36-42 persons",
  "Conference room range from 16-24 persons",
  "Capacity from 8-43 persons",
  "Cocktail arrangement from 8-60 persons",
  "Sterling Reception and Welcome area on floor 10",
  "Club Lounge with terrace",
  "Cafe area"
]

export default function MeetingConferenceRooms() {
  const contactUs = useRef(null);
  return (
    <>
      <Card
        direction={"right"}
        image={"/meeting-room.png"}
        title={"Meeting & Conference Rooms"}
        list = {listItems}
        description={
          `If you are looking to host a meeting or event in Sofia or Istanbul, then Sterling is your perfect choice! Choose us as your reliable partner!`
        }
        hasContactBtn={true}
        hasLearnMoreBtn={false}
        hasBullets={true}
        sideText={"Present your business"}
        backgroundColor={"#FFFFFF"}
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <KeyAdvantages
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />

      <ListCard
        direction={"right"}
        image={"/meet-3.png"}
        title={"Meeting rooms in sofia"}
        description={thirdListItems}
        backgroundColor={"#FFFFFF"}
        hasLearnMoreBtn={false}
        hasAdvantages={false}
        hasContactBtn={false}
        sideText={"Sofia, Serdika Center"}
      />
      <ListCard
        direction={"left"}
        image={"/meet-4.png"}
        title={"Meeting rooms in ISTANBUL"}
        description={secondListItems}
        backgroundColor={"#a0a0a2"}
        hasLearnMoreBtn={false}
        hasAdvantages={false}
        hasContactBtn={false}
        sideText={"Istanbul, Astoria"}
      />
      
      <Cover
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
        heading={"Let’s plan Your best event now!"}
        buttonText={"Contact Us"}
      />
      <div ref={contactUs}>
        <Inquiry direction="right" backgroundColor={"#FFFFFF"} city={"Sofia"}/>
      </div>
    </>
  );
}
