import { Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Testimonials.module.scss";
import MobileCard from "../../components/MobileCard/MobileCard";
import { useMediaQuery } from "@mui/material";

const companies = [
  {
    title: "Testimonials",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/disc_thumbnail.png",
    description: `<i>"Sterling have provided an extremely professional and smooth service from the outset. One of the most valuable aspects is their personal approach to our business, plus the management's willingness and readiness to adapt to our changing needs. Sterling ensures our team has a very attractive and comfortable working environment. Thank you to the team!"</i><br> 
    <br>- Victoria Davies`,
    sideText: "Discovery Channel",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/disc_thumbnail.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/game-logo.png",
    description: `<i>“I am really satisfied with all the services of Sterling since I moved in to the 10th floor on April 1st 2011. The location is very convenient, easy to reach. It is in the city center same distance to European side and Anatolian side. With the new additions to the underground system, and other types of public transports in Istanbul, the location is even reachable in 45 minutes from Kadıkoy district in Anatolian side. All the staff that has worked here since I moved in are very kind and helpful. They are always ready to assist for my non-ending questions. Hope as Gamesa Ruzgar company, we stay many years in Sterling.”</i><br> 
    <br>- Aybars Batılı <br>
    Administrator at Gamesa Turkiye`,
    sideText: "Gamesa",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/game-logo.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/tfs.png",
    description: `<i>"Sterling have provided an extremely professional and smooth service from the outset. One of the most valuable aspects is their personal approach to our business, plus the management's willingness and readiness to adapt to our changing needs. Sterling ensures our team has a very attractive and comfortable working environment. Thank you to the team!"</i><br> 
    <br>- Victoria Davies`,
    sideText: "TFS",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/tfs.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/EMClogo.png",
    description: `<i>"Setting up a new office has many challenges, like finding the best location, best infrastructure, high quality furniture & fit-out, a great business environment and excellent support provision. We were fortunate to find Sterling Serviced Offices who deliver all the above in a very corporate and professional way. We have been collaborating for several years and truly value them as a service partner!"</i><br> 
    <br>- Barbara O’Sullivan <br>
    Manager EMEA Real Estate & Project Management at EMC`,
    sideText: "EMC",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/EMClogo.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/Moet-logo.png",
    description: `<i>"Moet Hennessy Portfolio has been distributed in Turkey for many years but we came to the conclusion to grow our value leadership in the Luxury Wine & Spirits Turkish market by increasing our Brands image & desirability. For this we looked for a fast and efficient Office solution which can represent our products, and the cultural values they embody, blend tradition and innovation, and kindle dream and fantasy. We have been able to find these values at “Sterling Serviced Offices"</i><br> 
    <br>- Ogün Saka<br>
    Moët Hennessy Turkey | Country Manager`,
    sideText: "Moet",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/Moet-logo.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/fxcm-logo.png",
    description: `<i>"FXCM opened our Sofia office and expanded very rapidly in mere 6 months. Without Sterling Office, this would not have been possible.
    <br>
    I have experienced excellent service and communication from Borislava and the receptionists thus far. We are very satisfied with the office space, location and flexibility shown by Sterling Office."</i><br> 
    <br>- Tong Tsui<br>
    Managing Director<br>
    Forex Capital Markets`,
    sideText: "FXCM",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/fxcm-logo.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/eh%D0%B0.png",
    description: `<i>"As Euler Hermes we provide the best quality service to our clients and we expect the same from our suppliers. With its great location in Astoria towers, latest technology equipment, full support and always welcoming professional team, Sterling is our choice for our meetings and video conferences. Sterling provides not only premium service but also long term collaboration which is very valuable in today’s business world"</i>`,
    sideText: "EH",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/eh%D0%B0.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/Dassault-logo.png",
    description: `<i>"We have spent almost 5 years at the Sterling Astoria offices. Looking back at this experience, it can easily be concluded that Sterling has always offered us a high level support, close customer care and rapid reactions to our daily requests. The ‘‘boutique’’ office service in high standards and the always smiling faces of the Sterling team has supported us in many ways. We thank Mr. Giles Blanchard and his great team for the nice experience we had at their facilities. I would recommend Sterling to all companies seeking a serviced office which offers the necessary ‘extra mile’."</i><br> 
    <br>- Burak SÜSOY<br>
    General Manager , Turkey`,
    sideText: "Dassault",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/Dassault-logo.png",
  },
  {
    thumbnail: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/ALS-logo.png",
    description: `<i>"In 2013 part of our operation was relocated to Sofia and part of the success story of the smooth transition was the link we made with Sterling Offices. The offices were highly recommended to us by the Bulgarian Embassy and from the first viewing we have found the service and facilities have been second to none.
    <br>
    Borislava and her team are very accommodating, not only to our team here in Sofia but also to any of our UK colleagues that visit the offices. The location is in the heart of the city and it very much gives a ‘wow factor’ to anyone that has been to visit.
    <br>
    As a business we are glad we selected Sterling Offices as our office provider and are proud to show any of our clients around the facilities when visiting."</i><br> 
    <br>- Steve Smith<br>
    Head of Installation Resources<br>
    ALS`,
    sideText: "ALS",
    hasLearnMoreBtn: false,
    hasContactBtn: true,
    direction: "left",
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/images/testimonials1/ALS-logo.png",
  },
];

export default function Testimonials({ contactUs }) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const initials = {
    [`Discovery Channel`]: false,
    [`Gamesa`]: false,
    ["TFS"]: false,
    ["EMC"]: false,
    ["Moet"]: false,
    ["FXCM"]: false,
    ["EH"]: false,
    ["Dassault"]: false,
    ["ALS"]: false,
  };
  const [currentCompany, setCurrentCompany] = useState(companies[0]);
  const [isActive, setIsActive] = useState({
    [`Discovery Channel`]: false,
    [`Gamesa`]: false,
    ["TFS"]: false,
    ["EMC"]: false,
    ["Moet"]: false,
    ["FXCM"]: false,
    ["EH"]: false,
    ["Dassault"]: false,
    ["ALS"]: false,
  });
  return Boolean(isMobile) ? (
    <>
      {companies.map((company, index) => (
        <MobileCard
          key={index}
          image={company.image}
          title={company?.title}
          sideText={company.sideText}
          hasContactBtn={companies.length - 1 === index ? true : false}
          style={{marginTop:"2%"}}
          hasLearnMoreBtn={false}
          backgroundColor={"#FFFFFF"}
          description={company.description}
          direction={"right"}
          hasBorder={true}
          contactUsHref={() =>
            contactUs.current.scrollIntoView({
              block: "center",
              inline: "center",
              behavior: "smooth",
            })
          }
        />
      ))}
    </>
  ) : (
    <div className={styles["testimonials-container"]}>
      <Grid
        container
        alignItems="center"
        style={{
          backgroundColor: "#FFFFFF",
          color: "#181E24",
        }}
        className={styles[`testimonials-grid-container`]}
      >
        <Grid item xs={6} className={styles["info-container-testimonials"]}>
          <h2 className={styles.title}>Testimonials</h2>
          <h4 className={styles["company-name"]}>{currentCompany.sideText}</h4>
          <p
            dangerouslySetInnerHTML={{ __html: currentCompany?.description }}
            className={styles.description}
          ></p>
          <div className={styles["buttons-container"]}>
            <a
              className={styles[`contact-btn`]}
              onClick={() =>
                contactUs.current.scrollIntoView({
                  block: "center",
                  inline: "center",
                  behavior: "smooth",
                })
              }
            >
              CONTACT US
            </a>
          </div>
        </Grid>
        <Grid item xs={6} className={styles["image-container"]}>
          <img src={currentCompany?.image} alt="img" />
        </Grid>
      </Grid>
      <Divider />
      <div className={styles["all-companies-container"]}>
        <Grid container spacing={1}>
          {companies?.map((company, index) => (
            <Grid
              textAlign={"center"}
              item
              xs={1}
              key={index}
              className={`${styles["company-grid-item"]} ${
                isActive[company.sideText] ? styles["active"] : ""
              }`}
            >
              <img
                className={styles["company-logo"]}
                src={company.thumbnail}
                onClick={() => {
                  setCurrentCompany(company);
                  setIsActive({
                    ...initials,
                    [company.sideText]: !isActive[company.sideText],
                  });
                }}
              />
              
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

/*<img
                className={styles["company-logo"]}
                src={company.thumbnail}
                onClick={() => {
                  setCurrentCompany(company);
                  setIsActive({
                    ...initials,
                    [company.sideText]: !isActive[company.sideText],
                  });
                }}
              />
               */