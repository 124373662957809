import React, { useState } from "react";
import styles from "./Gallery.module.scss";
import { ImageList, ImageListItem, useMediaQuery } from "@mui/material";

export default function Gallery({
  hasOptions = false,
  SterlingSofia,
  SterlingIstanbul,
  currentImageSet,
  setCurrentImageSet,
  locationHeading,
  hasTitle = true,
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [isActive, setIsActive] = useState({ sofia: true, istanbul: false });
  const [currentImage, setCurrentImage] = useState(currentImageSet[0]);

  return (
    <>
    {isMobile && <div className={styles["gallery-component"]}>
      <div className={styles["gallery-component__heading"]}>
        {hasTitle && <h2 style={{marginLeft:"0px",marginBottom:"-5%"}}>GALLERY</h2>}
        {locationHeading && <p style={{fontSize:"18px"}}>{locationHeading}</p>}
        {hasOptions && (
          <div>
            <button
              className={`${styles["options-button"]} ${
                isActive.sofia ? styles["active"] : ""
              }`}
              onClick={() => {
                setCurrentImageSet(SterlingSofia);
                setIsActive({ sofia: true, istanbul: false });
              }}
            >
              STERLING SOFIA
            </button>
            <button
              className={`${styles["options-button"]} ${
                isActive.istanbul ? styles["active"] : ""
              }`}
              onClick={() => {
                setCurrentImageSet(SterlingIstanbul);
                setIsActive({ sofia: false, istanbul: true });
              }}
            >
              STERLING ISTANBUL
            </button>
          </div>
        )}
      </div>
      <div className={styles["gallery-container"]}>
        <div className={styles.gallery}>
          <img src={currentImage} />
        </div>
        {isMobile ? (
          <ul className={styles["gallery-thumbs"]}>
            {currentImageSet.map((image, index) => (
              <li key={index} className={styles["image-container"]}>
                <img
                  src={image}
                  alt="gallery-image"
                  loading="lazy"
                  onClick={() => setCurrentImage(image)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <ImageList
            sx={{ width: 200, height: 645 }}
            className={styles["gallery-thumbs"]}
            cols={1}
            gap={10}
          >
            {currentImageSet.map((image, index) => (
              <ImageListItem key={index} className={styles["image-container"]}>
                <img
                  srcSet={`${image}?w=183&h=183&fit=crop&auto=format&dpr=2 2x`}
                  src={`${image}?w=183&h=183&fit=crop&auto=format`}
                  alt="gallery-image"
                  loading="lazy"
                  onClick={() => setCurrentImage(image)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </div>
    </div>}
    {!isMobile && <div className={styles["gallery-component"]}>
      <div className={styles["gallery-component__heading"]}>
        {hasTitle && <h2>GALLERY</h2>}
        {locationHeading && <p>{locationHeading}</p>}
        {hasOptions && (
          <div>
            <button
              className={`${styles["options-button"]} ${
                isActive.sofia ? styles["active"] : ""
              }`}
              onClick={() => {
                setCurrentImageSet(SterlingSofia);
                setIsActive({ sofia: true, istanbul: false });
                setCurrentImage(SterlingSofia[0])
              }}
            >
              STERLING SOFIA
            </button>
            <button
              className={`${styles["options-button"]} ${
                isActive.istanbul ? styles["active"] : ""
              }`}
              onClick={() => {
                setCurrentImageSet(SterlingIstanbul);
                setIsActive({ sofia: false, istanbul: true });
                setCurrentImage(SterlingIstanbul[0])
              }}
            >
              STERLING ISTANBUL
            </button>
          </div>
        )}
      </div>
      <div className={styles["gallery-container"]}>
        <div className={styles.gallery}>
          <img src={currentImage} />
        </div>
        {isMobile ? (
          <ul className={styles["gallery-thumbs"]}>
            {currentImageSet.map((image, index) => (
              <li key={index} className={styles["image-container"]}>
                <img
                  src={image}
                  alt="gallery-image"
                  loading="lazy"
                  onClick={() => setCurrentImage(image)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <ImageList
            sx={{ width: 200, height: 645 }}
            className={styles["gallery-thumbs"]}
            cols={1}
            gap={10}
          >
            {currentImageSet.map((image, index) => (
              <ImageListItem key={index} className={styles["image-container"]}>
                <img
                  srcSet={`${image}?w=183&h=183&fit=crop&auto=format&dpr=2 2x`}
                  src={`${image}?w=183&h=183&fit=crop&auto=format`}
                  alt="gallery-image"
                  loading="lazy"
                  onClick={() => setCurrentImage(image)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </div>
    </div>
    }
    </>
  );
}
