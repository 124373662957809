import React, { useRef } from "react";
import Card from "../../../components/Card/Card";
import Gallery from "../../../components/Gallery/Gallery";
import CarouselComponent from "../../../components/CarouselComponent/CarouselComponent";
import IndividualLocation from "../../../components/IndividualLocation/InvidiualLocation";
import Inquiry from "../../Inquiry/Inquiry";
import Cover from "../../../components/Cover/Cover";

const location = [
  {
    address: "48, Sitnyakovo Boulevard 1505, Floor 10, Sofia, Bulgaria",
    phone: "+359 (0)2 926 4333 | +359 (0)2 926 4133",
    email: "sofia@sterlingoffice.com",
    image: "/sterling-sofia.png",
    name: "Sterling Serdika Center",
    location: { lat: 42.692072338290025, lng: 23.353466725137512 },
  },
];

const SterlingSofia = [
  "/sofia/1.jpeg",
  "/sofia/2.jpg",
  "/sofia/3.jpg",
  "/sofia/4.jpg",
  "/sofia/5.jpg",
  "/sofia/6.jpg",
  "/sofia/7.jpg",
  "/sofia/8.jpg",
  "/sofia/9.jpg",
  "/sofia/10.jpg",
  "/sofia/11.jpg",
  "/sofia/12.jpg",
  "/sofia/13.jpg",
  "/sofia/14.jpg",
  "/sofia/15.jpg",
  "/sofia/16.jpg",
  "/sofia/17.jpg",
  "/sofia/18.jpg",
  "/sofia/19.jpg",
  "/sofia/20.jpg",
  "/sofia/21.jpg",
  "/sofia/22.jpg",
  "/sofia/23.jpg",
  "/sofia/24.jpg",
  "/sofia/25.jpg",
  "/sofia/26.jpg",
  "/sofia/27.jpg",
  "/sofia/28.jpg",
  "/sofia/29.jpg",
  "/sofia/30.jpg",
  "/sofia/31.jpg",
  "/sofia/32.jpg",
  "/sofia/33.jpg",
  "/sofia/34.jpg",
  "/sofia/35.jpg",
];

export default function SofiaLocation() {
  const contactUs = useRef(null);
  return (
    <>
      <Card
        title={"Sterling Sofia"}
        description={`Sterling Offices at Serdika offer the advantages of city center closeness and excellent transport communication - next to Oborishte St., within minutes from the heart of Sofia and just 15 minutes drive to the Sofia International Airport. There are 5 bus and 3 tram lines, as well as an office shuttle to & from the nearest metro station at Eagles Bridge. 

        Located on top 10th floor clients can enjoy the spectacular views of Sofia and Vitosha Mountain and access to the popular Serdika Shopping Center with over 200 shops, cafes, restaurants, bank services as well as ample parking. 
        
        This outstanding property has Gold DGNB certificate for sustainable building and assures: highly efficient motorized sun protection, double-skin façade with openable windows, plenty of natural light to reach any place in your office, ventilation system for fresh air supply with temperature regulation twice per hour.`}
        image={"/sterling-sofia.png"}
        hasContactBtn={true}
        learnMoreBtnText={"VIDEO TOUR"}
        hasLearnMoreBtn={true}
        learnMoreHref={"https://www.youtube.com/watch?v=3vepixlqXas&t=85s"}
        sideText={`About the location`}
        backgroundColor={"#FFFFFF"}
        direction={"right"}
        list={[]}
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <CarouselComponent city={"Sofia"}/>
      <Gallery hasOptions={false} currentImageSet={SterlingSofia} />
      <Cover
        heading={"3D VIRTUAL TOUR"}
        buttonText={"TAKE ME TO STERLING"}
        link={`https://mpembed.com/show/?m=ea4GiqWbgT7`}
      />
      
      <IndividualLocation location={location} />
      <div ref={contactUs}>
        <Inquiry direction="right" backgroundColor={"#FFFFFF"} city={"Sofia"}/>
      </div>
    </>
  );
}
