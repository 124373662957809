import React, { useRef } from "react";
import Card from "../../components/Card/Card";
import ListCard from "../../components/ListCard/ListCard";
import Inquiry from "../Inquiry/Inquiry";

const servicedOffices = [
  {
    title: "Serviced Offices",
    sideText: "Create the best impression",
    direction: "left",
    image: "/servicedOffice.png",
    backgroundColor: "#FFFFFF",
    description: `Our centres provide the best of everything. We choose the best buildings, in the best locations. We implement cutting edge corporate office design and fit-out to a world-class standard, featuring glass, stone and natural wood finishes. Lighting, furniture and IT systems are all state of the art, creating a superb business environment. We incorporate nature and environmentally friendly practices as much as we can.  

    Our centres are not branded, you can present your organisation in any way you choose, without ever compromising your image. It’s Your office. Your partners, colleagues and customers will know you mean business as soon as they see your address and meet you at your office.`,
  },
  {
    title: "Flexible & Cost Efficient",
    sideText: "Affordability and flexibility",
    image: "/key-advantages.png",
    description: `Flexibility, agility and affordability are keys to achieving success and productivity in today’s rapidly changing business environment. Sterling’s office services are designed to allow maximum flexibility so that you have alternative, progressive and cost-effective options to help your business thrive. Monthly fees are fixed and inclusive of substantial amount of business services and Zero Investment. Move in and Be operational from Day 1. 
    
    You pay a set monthly fee for your serviced office, and you pay as you go for any other support services. If you need more space, you can  simply expand to a larger office, or you can downsize - when you need to. Sterling monthly fees are clear and transparent and you can forecast your monthly workspace expenses precisely`,
    backgroundColor: "#A0A0A2",
    direction: "right",
  },
];

const montlyFees = [
  "Office Monthly rent",
  "Office furniture",
  "Fit out & design",
  "Access to Shared Areas ",
  "All Utilities",
  "Building management fees",
  "Daily cleaning and disinefection",
  "Maintenance ",
  "24/7 Building Security",
  "24/7 Access",
  "Reception & Welcoming Services",
  "3rd Party insurance",
];

const ourClients = [
  "International companies entering new markets or expanding their operations",
  "Corporations seeking premium flexible or additional workspace",
  "Start-ups who need to control their initial costs",
  "Businesses who appreciate and rely on great  location, flexibility and support of a Sterling office",
  "Freelances  needing an office close to home, with a professional corporate environment",
  "Special projects office for companies with a specific project or tender",
  "Enterprises using serviced offices & coworking worldwide",
  "Multinational companies who appreciate Sterling benefits",
];

export default function ServicedOffices() {
  const contactUs = useRef(null);
  return (
    <>
      {servicedOffices.map((perks, index) => (
        <Card
          title={perks.title}
          list={[]}
          description={perks.description}
          direction={perks.direction}
          image={perks.image}
          sideText={perks.sideText}
          backgroundColor={perks.backgroundColor}
          key={index}
          hasLearnMoreBtn={false}
          contactUsHref={() =>
            contactUs.current.scrollIntoView({
              block: "center",
              inline: "center",
              behavior: "smooth",
            })
          }
        />
      ))}
      <ListCard
        direction={"left"}
        image={"/services-3.png"}
        title={"Monthly Fees Include"}
        description={montlyFees}
        backgroundColor={"#FFFFFF"}
        hasLearnMoreBtn={false}
        hasAdvantages={false}
        hasContactBtn={false}
        sideText={"Affordability and flexibility"}
      />
      <ListCard
        direction={"right"}
        image={"/our-clients.png"}
        title={"Our Clients"}
        description={ourClients}
        backgroundColor={"#A0A0A2"}
        hasLearnMoreBtn={false}
        hasAdvantages={false}
        hasContactBtn={false}
        sideText={"Business sectors"}
      />
      <div ref={contactUs}>
        <Inquiry direction="left" backgroundColor={"#FFFFFF"} city={"Sofia"}/>
      </div>
    </>
  );
}
