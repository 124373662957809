import React from "react";
import { Grid } from "@mui/material";
import styles from "./Article.module.scss";
import { NavLink } from "react-router-dom";

export default function Article() {
  return (
    <Grid container>
      <Grid item xs={12} lg={6} className={styles["article-info-container"]}>
        <NavLink to="/">
          <img src="back-arrow.png" />
        </NavLink>
        <h3>Sofia: The Underground Innovation Hub of Europe</h3>
        <div className={styles["text-container"]}>
          Bulgaria, a small, unassuming country nestled on the south east corner
          of Europe, is actually an unlikely incubator for some of Europe’s
          newest and brightest businesses. This year, Sofia was included in fDi
          Magazine and TNW’s Technology Cities of the Future report, citing
          Sofia as the 7th most cost-effective city for business in tech.
          Eastern Europe’s developing technology and innovation ecosystems are
          not a new phenomenon, but what makes Sofia, Bulgaria’s capital, so
          special? As the oldest country in Europe, founded in 681, innovation
          is rooted in Bulgarian history, having invented the Cyrillic alphabet
          by Saints Cyril and Methodius which is now the basis of languages
          across Eastern Orthodox Slavic countries. Other key innovators of
          Bulgaria include Stamen Grigorov, a Bulgarian scientist that
          discovered the bacteria that turns milk into yogurt and John
          Atanasoff, who invented the first electronic digital computer in the
          1930s. Bulgaria’s location plays a crucial role in its growing
          popularity for IT companies. Sofia is in a ‘goldilocks zone’, still in
          the European Union but outside of the competitive circle of central
          and Western European countries, making it a close outsourcing hub for
          businesses across the continent. Many multinationals have chosen Sofia
          as their base as a result, such as German software company SuSE and
          instant messaging software company Viber. On the other hand, it isn’t
          just established businesses that are benefiting from Sofia’s unique
          characteristics. Sofia was ranked by Forbes as one of the top ten best
          countries in the world to start a business, listing reasons such as a
          very low-income tax rate (10%), one of the fastest internet speeds in
          the world, and also access to EU investment funds. Additionally,
          Bulgaria is home to a highly skilled workforce. In high-schools across
          the country, there is a focus on IT and mathematics, as well as a
          solid second language education. This combination creates individuals
          who are perfect for the international nature of the IT sector. For
          this reason, especially in the tech industry, labour relies very
          little on migration. Only 6% of the workforce across Eastern Europe is
          composed of immigrants, whereas in the UK, for example, 44% of the
          private tech start-up industry are migrants. The booming tech industry
          has done fantastic things for Bulgaria, not only has the country seen
          steady economic growth, but also has been hugely beneficial in
          attracting innovation and talent. Interestingly, although fDi’s report
          states that most countries rely on government-led initiatives to help
          create these ‘tech cities’, Bulgaria’s success comes from mostly a
          private-sector led effort through investment funds such as Eleven,
          Startup Accelerator, Neveq and LAUNCHub. Additionally, infrastructure
          to support innovation has emerged to support these businesses, such as
          Sterling Office Group, which was the first serviced office operator in
          Bulgaria in 2002. As the working tendencies have evolved over the last
          20 years, a demand for flexible workspace prompted the creation of
          This Way Coworking Hub, Sterling’s sister company. Coworking and
          flexible workspaces provide a wide range of ways that companies can
          meet, work and collaborate. As a result, Sofia is the perfect place
          for not only incubating start-ups, but also for outsourcing for
          established businesses. The lifestyle is easy, everything is in very
          close proximity, and the culture is laidback and friendly. Not to
          mention, Sofia is located on the foothills of a the beautiful Vitosha
          mountain, and Bulgaria is home to some of the most picturesque and
          untouched nature. Could Sofia be your next destination for business?
          Contact us, at This Way, to use our coworking and private office space
          as your base during your exploration of Bulgaria’s innovation hub.
        </div>
      </Grid>
      <Grid item xs={12} lg={6} className={styles["image-container"]}>
        <img src="/office.png" />
      </Grid>
    </Grid>
  );
}
