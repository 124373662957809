import React, { useState } from "react";
import { Divider, Grid, Stack, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./styles.scss";
import { useMediaQuery } from "@mui/material";
import MobileLocations from "../MobileLocations/MobileLocations";
import GoogleMaps from "../GoogleMaps/GoogleMaps";

const locations = [
  {
    address: "48, Sitnyakovo Boulevard 1505, Floor 10, Sofia, Bulgaria",
    phone: "+359 (0)2 926 4333 | +359 (0)2 926 4133",
    email: "sofia@sterlingoffice.com ",
    image: "/sterling-sofia.png",
    name: "Sterling Serdika Center",
    location: { lat: 42.692072338290025, lng: 23.353466725137512 },
  },
  {
    address: "127 Büyükdere Cad. 34394, Floor 8, Astoria, Istanbul, Turkey",
    phone: "+90 (0)212 340 7600 | +90 (0)212 340 7601",
    email: "istanbul@sterlingoffice.com",
    image: "/sterling-istanbul.png",
    name: "Sterling Istanbul",
    location: { lat: 41.07620331571032, lng: 29.012647482482105 },
  },
  {
    address: "Office A, 4th Floor, 2 Ivan Abadjiev Street 1000, Sofia, Bulgaria",
    phone: "+359 (0)2 9264264",
    email: "info@thisway.com",
    image: "/sterling-sofia2.png",
    name: "This Way,Sofia",
    location: { lat: 42.66253722826454, lng: 23.38091786950156 },
  },
];

export default function LocationsComponent() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [media, setMedia] = useState("map");
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return Boolean(isMobile) ? (
    <MobileLocations locations={locations} />
  ) : (
    <Grid className="locations-container" container alignItems={"stretch"}>
      <Grid item xs={6} className="image-container">
        {media === "map" ? (
          <GoogleMaps
            address={value === "1" ? "Sterling, Bulgaria" : "Sofia, Bulgaria"}
            location={
              value === "1"
                ? { lat: 42.692072338290025, lng: 23.353466725137512 }
                : value === "2"
                ? { lat: 41.07620331571032, lng: 29.012647482482105 }
                : value === "3" 
                ? { lat: 42.66253722826454, lng: 23.38091786950156 }

                : { lat: 42.696472922955834, lng: 23.323773003089357 }
            }
          />
        ) : (
          <img
            src={
              value === "1" ? "/sterling-sofia.png" : value === "2" ? "/sterling-istanbul.png" : "/sterling-sofia2.png"
            }
            alt="building"
            style={{ width: "100%", maxHeight: "100%" }}
          />
        )}
      </Grid>
      <Grid item xs={6} className="locations-info-container">
        <Grid container>
          <Grid item xs={1} style={{ borderRight: "1px solid white" }}>
            <Stack
              divider={<Divider flexItem className={`${media === "image" && "imageSelected"}`}/>}
              spacing={1}
              className={`options-container ${media === "map" && "mapSelected"}`}
            >
                <img
                  src="/location-icon.png"
                  alt="location-icon"
                  onClick={() => setMedia("map")}
                />
              <img
                src="/media-icon.png"
                alt="media-icon"
                onClick={() => setMedia("image")}
              />
            </Stack>
          </Grid>
          <Grid item xs={11} className="locations-tabs-container">
            <h2 className="title">LOCATIONS</h2>
            <h2 className={"innerTitle"}>MARK THE SPOT</h2>
            <TabContext value={value}>
              <TabList
                TabIndicatorProps={{
                  style: {
                    top: "0px",
                    backgroundColor: "#FFFFFF",
                  },
                }}
                onChange={handleChange}
              >
                <Tab label="Sterling Sofia" value="1" />
                <Tab label="Sterling Istanbul" value="2" />
                <Tab label="Тhis Way Sofia" value="3" />
              </TabList>



              <TabPanel value="1">
                <Typography variant="subtitle2">ADDRESS</Typography>
                <Typography variant="body1">
                 48, Sitnyakovo Boulevard 1505, Floor 10, Sofia, Bulgaria
                </Typography>
                <Typography variant="subtitle2">PHONE</Typography>
                <Typography variant="body1">
                  +359 (0)2 926 4333 | +359 (0)2 926 4133
                </Typography>
                <Typography variant="subtitle2">EMAIL</Typography>
                <Typography variant="body1">
                  sofia@sterlingoffice.com 
                </Typography>
              </TabPanel>



              <TabPanel value="2">
                <Typography variant="subtitle2">ADDRESS</Typography>
                <Typography variant="body1">
                127 Büyükdere Cad. 34394, Floor 8, Astoria, Istanbul, Turkey
                </Typography>
                <Typography variant="subtitle2">PHONE</Typography>
                <Typography variant="body1">+90 (0)212 340 7600 | +90 (0)212 340 7601</Typography>
                <Typography variant="subtitle2">EMAIL</Typography>
                <Typography variant="body1">
                  istanbul@sterlingoffice.com
                </Typography>
              </TabPanel>




              <TabPanel value="3">
                <Typography variant="subtitle2">ADDRESS</Typography>
                <Typography variant="body1">
                Office A, 4th Floor, 2 Ivan Abadjiev Street 1000, Sofia, Bulgaria
                </Typography>
                <Typography variant="subtitle2">PHONE</Typography>
                <Typography variant="body1">+359 (0)2 9264264</Typography>
                <Typography variant="subtitle2">EMAIL</Typography>
                <Typography variant="body1">
                info@thisway.com
                </Typography>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
