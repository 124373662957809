import React from "react";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import styles from "./MobileCard.module.scss";

export default function MobileCard({
  image,
  title,
  description,
  sideText,
  hasContactBtn = true,
  hasLearnMoreBtn = true,
  backgroundColor,
  hasBorder,
  hasAdvantages = false,
  contactUsHref,
  learnMoreHref,
  learnMoreBtnText = "LEARN MORE",
}) {
  let backgroundColorIdentifier =
    backgroundColor === "#FFFFFF" ? "white" : "gray";
  return (<>
    {sideText === "Discovery Channel" ?
      <Grid
        container
        direction={"column"}
        //alignItems="center"
        justifyContent={"flex-start"}
        style={{
          backgroundColor: backgroundColor,
          color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
          marginTop:"4%"
        }}
        className={styles[`home-container`]}
      >
        <Grid item xs={12}>
          <img
            className={`${styles["image-container"]} ${
              hasBorder && styles.border
            }`}
            src={image}
            alt="img"
          />
        </Grid>
        <Grid item xs={12} style={{marginTop:"4%"}}>
          <h2 className={styles.title} style={{marginLeft:"5%",paddingBottom:"5px",fontSize:"21px"}}>{title}</h2>
          <div
          className={`${styles[`side-text-container`]} ${
            styles[backgroundColorIdentifier]
          }`}
          style={{marginTop:"-5%",width:"100%",marginBottom:"-5%",textAlign:"left",fontSize:"18px"}}
        >
          <p>{sideText}</p>
        </div>
        </Grid>
        <Grid item xs={12} className={styles["info-container"]}>
          {hasAdvantages ? (
            <div>{description}</div>
          ) : (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className={styles.description}
              style={{marginTop: "-3%"}}
            ></p>
          )}
          <div className={styles["buttons-container"]}>
            {hasContactBtn && (
              <a
                className={`${styles[`contact-btn`]} ${
                  styles[backgroundColorIdentifier]
                }`}
                onClick={() => contactUsHref()}
              >
                CONTACT US
              </a>
            )}
            {hasLearnMoreBtn && (
              <a
                className={`${styles[`contact-btn`]} ${
                  styles[backgroundColorIdentifier]
                }`}
                to={learnMoreHref}
              >
                {learnMoreBtnText}
              </a>
            )}
          </div>
        </Grid>
      </Grid>
      :
      <Grid
        container
        direction={"column"}
        //alignItems="center"
        justifyContent={"flex-start"}
        style={{
          backgroundColor: backgroundColor,
          color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
        }}
        className={styles[`home-container`]}
      >
        <Grid item xs={12} style={{maxWidth:"100%"}}>  
          <img
            className={`${styles["image-container"]} ${
              hasBorder && styles.border
            }`}
            src={image}
            alt="img"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
          
        </Grid>
        <Grid item xs={12} style={{marginTop:"4%",maxWidth:"100%"}}>
          <h2 className={styles.title} style={{marginLeft:"4%",paddingBottom:"5px",fontSize:"21px"}}>{title}</h2>
          <div
          className={`${styles[`side-text-container`]} ${
            styles[backgroundColorIdentifier]
          }`}
          style={{marginTop:"-5%",width:"100%",marginBottom:"-5%",textAlign:"left",fontSize:"16px",textTransform:"uppercase",whiteSpace:"normal",marginLeft:"-1%"}}
        >
          <p>{sideText}</p>
        </div>
        </Grid>
        <Grid item xs={12} className={styles["info-container"]} style={{maxWidth:"100%"}}>
          {hasAdvantages ? (
            <div>{description}</div>
          ) : (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className={styles.description}
              style={{marginTop: "-3%"}}
            ></p>
          )}
          <div className={styles["buttons-container"]}>
            {hasContactBtn && (
              <a
                className={`${styles[`contact-btn`]} ${
                  styles[backgroundColorIdentifier]
                }`}
                onClick={() => contactUsHref()}
              >
                CONTACT US
              </a>
            )}
            {hasLearnMoreBtn && (
              <a
                className={`${styles[`contact-btn`]} ${
                  styles[backgroundColorIdentifier]
                }`}
                style={{maxWidth:"100%"}}
                to={learnMoreHref}
              >
                {learnMoreBtnText}
              </a>
            )}
          </div>
        </Grid>
      </Grid>
    }
    </>
  );
}
