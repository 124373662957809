import React from "react";
import { Grid } from "@mui/material";
import MobileCard from "../MobileCard/MobileCard";
import { useMediaQuery } from "@mui/material";
import styles from "./ListCard.module.scss";

export default function ListCard({
  direction,
  image,
  title,
  description,
  sideText,
  hasContactBtn = true,
  hasLearnMoreBtn = true,
  backgroundColor,
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");

  return Boolean(isMobile) ? (
    <MobileCard
      direction={direction}
      image={image}
      title={title}
      description={description}
      sideText={sideText}
      hasContactBtn={hasContactBtn}
      hasLearnMoreBtn={hasLearnMoreBtn}
      backgroundColor={backgroundColor}
    />
  ) : (
    <Grid
      container
      alignItems="center"
      style={{
        backgroundColor: backgroundColor,
        color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
      }}
      className={`${styles[`home-container`]} ${styles[direction]}`}
    >
      {direction === "left" && (
        <>
          <Grid item xs={6} className={styles["info-container"]}>
            <h2 className={styles.title}>{title}</h2>
            <h2 className={styles.innerTitle} style={{marginBottom:"-2%",marginTop:"-8%"}}>{sideText}</h2>
            <ul className={styles.list}>
              {description.map((item, index) => (
                <li
                  className={`${styles["list-item"]} ${styles[direction]}`}
                  key={index}
                >
                  {item}
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={6} className={styles["image-container"]} style={{height: "100%"}}>
            <img src={image} alt="img" />
          </Grid>
        </>
      )}
      {direction === "right" && (
        <>
          <Grid item xs={6} className={styles["image-container"]} style={{height: "100%"}}>
            <img src={image} alt="img" />
          </Grid>
          <Grid item xs={6} className={styles["info-container"]}>
            <h2 className={styles.title}>{title}</h2>
            <h2 className={styles.innerTitle} style={{marginBottom:"-2%",marginTop:"-8%"}}>{sideText}</h2>
            <ul className={styles.list}>
              {description.map((item, index) => (
                <li
                  className={`${styles["list-item"]} ${styles[direction]} ${backgroundColor !== "#FFFFFF" && styles["darker"]}`}
                  key={index}
                >
                  {item}
                </li>
              ))}
            </ul>
          </Grid>
        </>
      )}
    </Grid>
  );
}
