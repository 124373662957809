import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";

function StayUpToDate() {
  return (
    <div className="stay-up-to-date-container">
      <div className="stay-up-to-date-text">
        <h1>Stay Up To Date</h1>
      </div>
      <div className="buttons-container">
        <a href="https://www.facebook.com/SterlingOffices/ ">
           Follow us on Facebook <img src="/arrow.png" />
        </a>

        <a href="https://www.linkedin.com/company/sterling-serviced-office-group/">
           Follow us on LinkedIn <img src="/arrow.png" />
        </a>
        
        <a href="https://www.youtube.com/user/SterlingOffice">
          Follow us on YouTube <img src="/arrow.png" />
        </a>

      </div>
    </div>
  );
}

export default StayUpToDate;
