import { Grid } from "@mui/material";
import React, { useState } from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import styles from "./Inquiry.module.scss";
import { useMediaQuery } from "@mui/material";
import classNames from "classnames";

export default function Inquiry({ direction = "left", backgroundColor, city }) {
  

  const isMobile = useMediaQuery("(max-width: 900px)");
  let backgroundColorIdentifier =
    backgroundColor === "#FFFFFF" ? "white" : "gray";

  return (
    <>
      {isMobile && (
        <Grid
          container
          style={{
            backgroundColor: backgroundColor,
            color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
          }}
          className={styles["inquiry-container"]}
        >
          {direction === "left" ? (
            <>
              <Grid
                item
                xs={12}
                lg={6}
                className={styles["info-container-inquiry"]}
              >
                <ContactForm
                  color={backgroundColor === "#FFFFFF" ? "#000000" : "#FFFFFF"}
                 
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                className={styles["image-container"]}
                style={{ height: "100%" }}
              >
                {city === "Sofia" ? (
                  <img src={"/Inquire-sofia.png"} alt="img" />
                ) : city === "Istanbul" ? (
                  <img src={"/inquiry-picture.png"} alt="img" />
                ) : (
                  ""
                )}
              </Grid>
            </>
          ) : (
            <>
              {!isMobile && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  className={styles["image-container"]}
                  style={{ height: "100%" }}
                >
                  {city === "Sofia" ? (
                    <img src={"/Inquire-sofia.png"} alt="img" />
                  ) : city === "Istanbul" ? (
                    <img src={"/inquiry-picture.png"} alt="img" />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                lg={6}
                className={styles["info-container-inquiry"]}
              >
                <ContactForm
                  color={backgroundColor === "#FFFFFF" ? "#000000" : "#FFFFFF"}
                 
                />
              </Grid>
              {isMobile && (
                <Grid item xs={12} lg={6} className={styles["image-container"]}>
                  {city === "Sofia" ? (
                    <img src={"/Inquire-sofia.png"} alt="img" />
                  ) : city === "Istanbul" ? (
                    <img src={"/inquiry-picture.png"} alt="img" />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}

      {!isMobile && (
        <Grid
          container
          style={{
            backgroundColor: backgroundColor,
            color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
            height: "100%",
          }}
          className={styles["inquiry-container"]}
        >
          {direction === "left" ? (
            <>
              <Grid
                item
                xs={12}
                lg={6}
                className={styles["info-container-inquiry"]}
              >
                <ContactForm
                  color={backgroundColor === "#FFFFFF" ? "#000000" : "#FFFFFF"}
                 
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                className={styles["image-container"]}
                style={{ height: "100%" }}
              >
                {city === "Sofia" ? (
                  <img src={"/Inquire-sofia.png"} alt="img" />
                ) : city === "Istanbul" ? (
                  <img src={"/inquiry-picture.png"} alt="img" />
                ) : (
                  ""
                )}
              </Grid>
            </>
          ) : (
            <>
              {!isMobile && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  className={styles["image-container"]}
                  style={{ height: "100%" }}
                >
                  {city === "Sofia" ? (
                    <img src={"/Inquire-sofia.png"} alt="img" />
                  ) : city === "Istanbul" ? (
                    <img src={"/inquiry-picture.png"} alt="img" />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                lg={6}
                className={styles["info-container-inquiry"]}
              >
                <ContactForm
                  color={backgroundColor === "#FFFFFF" ? "#000000" : "#FFFFFF"}
                 
                />
              </Grid>
              {isMobile && (
                <Grid item xs={12} lg={6} className={styles["image-container"]}>
                  <img src={"/inquiry-picture.png"} alt="img" />
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    </>
  );
}
