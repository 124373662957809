import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "@mui/material";
import MobileCard from "../MobileCard/MobileCard";
import { useMediaQuery } from "@mui/material";
import styles from "./Card.module.scss";

export default function Card({
  direction,
  image,
  title,
  description,
  list,
  sideText,
  hasContactBtn = true,
  hasLearnMoreBtn = true,
  backgroundColor,
  hasAdvantages = false,
  contactUsHref,
  hasBullets,
  learnMoreHref,
  learnMoreBtnText = "LEARN MORE",
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  let backgroundColorIdentifier =
    backgroundColor === "#FFFFFF" ? "white" : "gray";
  return Boolean(isMobile) ? (
    <MobileCard
      hasAdvantages={hasAdvantages}
      direction={direction}
      image={image}
      title={title}
      description={description}
      sideText={sideText}
      hasContactBtn={hasContactBtn}
      hasLearnMoreBtn={hasLearnMoreBtn}
      backgroundColor={backgroundColor}
      contactUsHref={contactUsHref}
      learnMoreHref={learnMoreHref}
      learnMoreBtnText={learnMoreBtnText}
    />
  ) : (
    <Grid
      container
      alignItems="center"
      style={{
        backgroundColor: backgroundColor,
        color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
      }}
      className={styles[`home-container`]}
    >
      {direction === "left" && (
        <>
          <Grid item xs={6} className={styles["info-container"]}>
            <h2 className={styles.title}>{title}</h2>
            <h2 className={styles.innerTitle}>{sideText}</h2>
            {hasAdvantages ? (
              <div className={styles["key-advantages"]}>{description}</div>
            ) : (
              <p
                dangerouslySetInnerHTML={{ __html: description }}
                className={styles.description}
              ></p>
            )}
            {list.length > 0 && (
              <ul
                className="list"
                style={{
                  width: "60%",
                  marginLeft: "14%",
                  marginBottom: "5%",
                  listStyle: "url('../../assets/bullet.svg')",
                }}
              >
                {list.map((el, i) => {
                  return (
                    <li
                      style={{
                        listStyle: "url('../../assets/bullet.svg')",
                        marginTop: "2%",
                      }}
                    >
                      <p>{el}</p>
                    </li>
                  );
                })}
              </ul>
            )}
            <div className={styles["buttons-container"]}>
              {hasContactBtn && (
                <a
                  className={`${styles[`contact-btn`]} ${
                    styles[backgroundColorIdentifier]
                  }`}
                  onClick={() => contactUsHref()}
                >
                  CONTACT US
                </a>
              )}
              {hasLearnMoreBtn && (
                <a
                  className={`${styles[`contact-btn`]} ${
                    styles[backgroundColorIdentifier]
                  }`}
                  href={learnMoreHref}
                >
                  {learnMoreBtnText}
                </a>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            height={"100%"}
            className={styles["image-container"]}
          >
            <img src={image} alt="img" />
          </Grid>
        </>
      )}
      {direction === "right" && (
        <>
          <Grid
            item
            xs={6}
            height={"100%"}
            className={styles["image-container"]}
          >
            <img src={image} alt="img" />
          </Grid>
          <Grid item xs={6} className={styles["info-container"]}>
            <h2 className={styles.title}>{title}</h2>
            <h2 className={styles.innerTitle}>{sideText}</h2>
            <p className={styles.description}>{description}</p>

            {list.length > 0 && (
              <ul className={styles.listt}>
                {list.map((item, index) => (
                  <li
                    className={`${styles["listt-item"]} ${styles[direction]}`}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}

            <div className={styles["buttons-container"]}>
              {hasContactBtn && (
                <a
                  className={`${styles[`contact-btn`]} ${
                    styles[backgroundColorIdentifier]
                  }`}
                  onClick={() => contactUsHref()}
                >
                  CONTACT US
                </a>
              )}
              {hasLearnMoreBtn && (
                <a
                  className={`${styles[`contact-btn`]} ${
                    styles[backgroundColorIdentifier]
                  }`}
                  href={learnMoreHref}
                >
                  {learnMoreBtnText}
                </a>
              )}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}
