import React, { useState } from "react";
import { history } from "../../config/stores";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./styles.scss";

export default function Footer() {
  const location = useLocation();
  return (
    <div
      className={`footer-container ${
        location.pathname.includes("PrivacyPolicy") && "display-none"
      }`}
    >
      <div className="footer-inner-container">
        <div className="footer-column">
          <span>LOCATIONS</span>

          <NavLink to={"/locations/sofia"} style={{ textDecoration: "none" }}>
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Sterling Sofia
            </p>
          </NavLink>

          <NavLink
            to={{pathname: ""}}
            style={{ textDecoration: "none" }}
          >
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.open("https://mpembed.com/show/?m=ea4GiqWbgT7")
              }}
            >
              Virtual tour Sofia
            </p>
          </NavLink>

          <NavLink
            to={"/locations/istanbul"}
            style={{ textDecoration: "none" }}
          >
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Sterling Istanbul
            </p>
          </NavLink>
        </div>
        <div className="footer-column">
          <span>SERVICES</span>
          <NavLink to={"/serviced-offices"} style={{ textDecoration: "none" }}>
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Serviced offices
            </p>
          </NavLink>

          <NavLink
            to={"/workspace-solutions"}
            style={{ textDecoration: "none" }}
          >
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Flexible Workspace Solutions
            </p>
          </NavLink>

          <NavLink to={"/meeting"} style={{ textDecoration: "none" }}>
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Meeting {"&"} Conference Rooms
            </p>
          </NavLink>

          <NavLink to={"/business-services"} style={{ textDecoration: "none" }}>
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Business Services
            </p>
          </NavLink>
        </div>
        <div className="footer-column">
          <span>WHY STERLING</span>
          <NavLink to={"/why"} style={{ textDecoration: "none" }}>
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Benefits
            </p>
          </NavLink>
          <NavLink to={"/why"} style={{ textDecoration: "none" }}>
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({
                  top: window.innerHeight * 4.2,
                  behavior: "smooth",
                });
              }}
            >
              Gallery
            </p>
          </NavLink>
        </div>
        <div className="footer-column">
          <span>ABOUT STERLING</span>
          <NavLink to={"/about"} style={{ textDecoration: "none" }}>
            <p
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Тhis is us
            </p>
          </NavLink>
        </div>
        <div className="footer-column large">
          <span>FOLLOW US</span>
          <div className="flex-container">
            <div
              className="social-icon facebook"
              onClick={() =>
                window.open("https://www.facebook.com/SterlingOffices/")
              }
            />
            <div
              className="social-icon youtube"
              onClick={() =>
                window.open("https://www.youtube.com/user/SterlingOffice ")
              }
            />
            <div
              className="social-icon linkedin"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/sterling-serviced-office-group/"
                )
              }
            />
          </div>
          <p>Sofia</p>
          <p id="small">E: sofia@sterlingoffice.com</p>
          <p id="small">T: +359 2 962 4111</p>
          <p>Istanbul</p>
          <p id="small">E: istanbul@sterlingoffice.com</p>
          <p id="small">T: +90 212 340 7600</p>
        </div>
      </div>
      <div className="footer-bottom-container">
        <div className="footer-bottom-inner-container">
          <p
            onClick={() => window.open(`/files/Privacy.pdf`)}
            style={{ whiteSpace: "nowrap", marginRight: "5%" }}
          >
            PRIVACY POLICY
          </p>
          <p
            onClick={() => window.open(`/files/Cookies.pdf`)}
            style={{ whiteSpace: "nowrap" }}
          >
            COOKIES POLICY
          </p>
        </div>
      </div>
    </div>
  );
}
