import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ContactTypes } from "../actions";

export const sendEmailEpic = (action$) =>
  action$.pipe(
    ofType(ContactTypes.SEND_EMAIL),
    switchMap(({ payload }) => {
      return ajax({
        url: `https://web-emails-dev.herokuapp.com/email`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic " + window.btoa("Fidweb1!:Off1c3AF1dw3b2020C0R0N4FTW!"),
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            console.log(response);
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );
