import React from "react";
import CarouselComponent from "../../components/CarouselComponent/CarouselComponent";
import Inquiry from "../Inquiry/Inquiry";

export default function BusinessServices() {
  return (
    <>
      <CarouselComponent city={"services"}/>
      <Inquiry direction="right" backgroundColor={"#FFFFFF"} city={"Sofia"}/>
    </>
  );
}
