import { styled } from "@mui/material/styles";
import React from "react";
import "./styles.scss";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

function Cover({ heading, buttonText, link, contactUsHref }) {
  return (
    <Root>
      <div className="cover-container">
        <div className="cover-text">
          <p>{heading}</p>
        </div>
        <div className="cover-button" style={{cursor:"pointer",display:"flex",justifyContent:"center"}}>
          <a href="https://mpembed.com/show/?m=ea4GiqWbgT7">{buttonText}</a>
        </div>
      </div>
    </Root>
  );
}

export default Cover;
