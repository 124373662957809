import React, { useRef } from "react";
import Card from "../../components/Card/Card";
import Inquiry from "../Inquiry/Inquiry";

const workspaceSolutions = [
  {
    title: "Flexible Workspace Solutions",
    sideText: "liquidity and multifunctionality",
    direction: "left",
    image: "/workspace01.png",
    backgroundColor: "#FFFFFF",
    description: `We tailor various flexible workspace solutions as per your company’s requirements.  Wether its hybrid workspace, on demand workspace, coworking or shared office, Monthly hybrid pass or Virtual office, we offer all the flexibility and range of services.   Working from Sterling Offices has many advantages – you will have to offices,hot desks, meeting rooms, video conferencing, internet and our Sterling business services as needed.  Furthermore, you will be working, meeting and creating from a very productive and inspiring environment with focus on wellbeing and sustainability. `,
  },
  {
    title: "Coworking",
    sideText: "Affordability and flexibility",
    image: "/workspace02.png",
    description: `Choose from a variety of private office or coworking options at our THIS WAY location, offering a distinct and premium workspace design, ambience and service; 2100 sq. m with 275 workstations, various meeting rooms, event space and ample shared amenities ranging from cafes, lounge areas, phone booths, shared library, meeting pods and more.`,
    backgroundColor: "#A0A0A2",
    direction: "right",
  },
];

export default function FlexibleWorkspaceSolutions() {
  const contactUs = useRef(null);
  return (
    <>
      {workspaceSolutions.map((solution, index) => (
        <Card
          title={solution.title}
          description={solution.description}
          sideText={solution.sideText}
          image={solution.image}
          direction={solution.direction}
          hasLearnMoreBtn={false}
          backgroundColor={solution.backgroundColor}
          key={index}
          list={[]}
          contactUsHref={() =>
            window.open("https://www.thisway.com/")
          }
        />
      ))}
      <div ref={contactUs}>
        <Inquiry direction="left" backgroundColor={"#FFFFFF"} city={"Sofia"}/>
      </div>
    </>
  );
}
