import React from "react";
import { Typography } from "@mui/material";
import styles from "./MobileLocations.module.scss";
import GoogleMaps from "../GoogleMaps/GoogleMaps";

export default function Locations({ locations }) {
  return (
    <div className={styles["locations-container"]}>
      
      <Typography variant="h6" className={styles["location-header"]}  style={{marginLeft:"3%",fontSize:"23px"}}>LOCATIONS</Typography>
      <div className={styles["helper-text"]} style={{fontSize:"18px"}}>
        <p style={{fontSize:"18px"}}>MARK THE SPOT</p>
      </div>
      {locations.map((location, index) => (
        <>
          <div className={styles["location-info"]}>
            <Typography variant="subtitle1" style={{marginTop:"3%" }}>
              {location.name}
            </Typography>
            <Typography variant="subtitle2" style={{marginTop:"8%"}}>ADDRESS</Typography>
            <Typography variant="body1">{location.address}</Typography>
            <Typography variant="subtitle2" style={{marginTop:"8%"}}>PHONE</Typography>
            <Typography variant="body1">{location.phone}</Typography>
            <Typography variant="subtitle2" style={{marginTop:"8%"}}>EMAIL</Typography>
            <Typography variant="body1">{location.email}</Typography>
          </div>
          <div className={styles["map-container"]}>
            <img src="/location-icon.png" alt="location-icon" />
            <GoogleMaps address={location.name} location={location.location} />
          </div>
          <div className={styles["building-container"]}>
            <img src="/media-icon.png" alt="media-icon" />
            <img
              className={styles["location-image"]}
              src={location.image}
              alt="building"
            />
          </div>
        </>
      ))}
    </div>
  );
}
