import React, { useRef } from "react";
import HomeCards from "../HomeCards/HomeCards";
import TakeALook from "../TakeALook/TakeALook";
import Testimonials from "../Testimonials/Testimonials";
import StayUpToDate from "../StayUpToDate/StayUpToDate";
import Inquiry from "../Inquiry/Inquiry";
import LocationsComponent from "../../components/LocationsComponent/LocationsComponent";

export default function Home() {
  const contactUs = useRef(null);
  return (
    <>
      <HomeCards contactUs={contactUs} />
      <TakeALook />
      <Testimonials contactUs={contactUs} />
      <StayUpToDate />
      <LocationsComponent />
      <div ref={contactUs}>
        <Inquiry backgroundColor={"#FFFFFF"} city={"Sofia"}/>
      </div>
    </>
  );
}
