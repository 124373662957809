import React, { useState } from "react";
import {
  Accordion,
  Grid,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import styles from "./KeyAdvantages.module.scss";
import { find } from "lodash";
import { NavLink } from "react-router-dom";

const keyAdvantages = [
  {
    title: "TOP LOCATION",
    image: "/accordion/meet-2.png",
    id: "panel1",
    description:
      "Мake a choice between the modern Sterling at Serdika Center with spectacular views and ample parking, or the impressive Astoria towers in the heart of Istanbul’s modern business district.",
  },
  {
    title: "ON DEMAND",
    image: "/accordion/meet-2.png",
    id: "panel2",
    description:
      "Our meeting facilities are available for your next meeting – efficiently and professionally supported, as per your requirement on an hourly, daily or monthly basis.",
  },
  {
    title: "FLEXIBLE",
    image: "/accordion/meet-2.png",
    id: "panel3",
    description:
      "We are not just flexible, we tailor to your specific requirements in terms of setting equipment, meeting rooms' arrangements and additinal services to support your event.",
  },
  {
    title: "AGILE",
    image: "/accordion/meet-2.png",
    id: "panel4",
    description:
      "You are free from tedious details and technical issues – concentrate on your business and let us manage your meetings efficiently. The Sterling team will welcome your guests, fully support or manage your event",
  },
  {
    title: "TAILOR-MADE",
    image: "/accordion/meet-2.png",
    id: "panel5",
    description:
      "We are not just flexible, we tailor to your specific requirements in terms of setting equipment, meeting rooms’ arrangements and additinal services to support your event.",
  },
  {
    title: "INSPIRATIONAL",
    image: "/accordion/meet-2.png",
    id: "panel7",
    description:
      "Enjoy a pleasant and productive meeting in a superb atmosphere. Our Club Lounge, Cafe and the exceptional Terrace with spectacular views will further enhance your experience",
  },
  {
    id: "panel8",
    title: "COST EFFECTIVE",
    image: "/accordion/meet-2.png",
    description:
      "Pay per use and give your business the first-class experience. Let us know about your requirements and we will offer you a tailor solution",
  },
  {
    id: "panel9",
    title: "VALUE ADDED BENEFITS",
    image: "/accordion/meet-2.png",
    description:
      "Become a Sterling partner with the privileges of our Silver Rewards card and benefit from special Sterling Meeting room packages and great discounts at hotels, shops, restaurants and other",
  },
  {
    title: "STERLING SUPPORT",
    image: "/accordion/meet-2.png",
    id: "panel6",
    description:
      "You are free from tedious details and technical issues – concentrate on your business and let us manage your meetings efficiently. The Sterling team will welcome your guests, fully support or manage your event",
  },
];

export default function KeyAdvantages({
  backgroundColor = "#A0A0A2",
  color = "#FFFFFF",
  contactUsHref,
}) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [expanded, setExpanded] = useState("panel1");
  const [currentPanelImage, setCurrentPanelImage] = useState(
    keyAdvantages[0].image
  );
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setCurrentPanelImage(find(keyAdvantages, { id: panel }).image);
  };
  return Boolean(isMobile) ? (
    <>
      <Grid
        style={{
          backgroundColor: backgroundColor,
          color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
        }}
        className={styles["mobile-environments"]}
        container
        //justifyContent={"center"}
      >
        
        
        {keyAdvantages.map((environment, index) => (
          <div className={styles["mobile-environments-container"]} key={index}>
            {index < 1 && <img
              className={styles.image}
              src={environment.image}
              alt="office-image"
              style={{width:"100%"}}
            />}
            {index < 1 && <>
              <h4 style={{fontSize:"21px"}}>KEY ADVANTAGES</h4>
              <div className={styles[`horizontal-text-container`]} style={{textAlign:"left",marginTop:"-5%"}}>
                <p style={{fontSize:"17px",textTransform:"uppercase"}}>The most professional way</p>
              </div>
            </>}
            <p className={styles["environment-title"]}>{environment.title}</p>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: environment.description }}
              style={{marginTop:'-5%'}}
            ></div>
          </div>
        ))}
      </Grid>
    </>
  ) : (
    <Grid
      container
      alignItems="center"
      style={{
        backgroundColor: backgroundColor,
        color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
      }}
      className={styles[`home-container`]}
    >
      <Grid item xs={6} className={styles["info-container"]}>
        <h2 className={styles.title}>Key Advantages</h2>
        <h2 className={styles.innerTitle}>The most professional way</h2>

        <div className={styles["key-advantages"]}>
          {" "}
          <Accordion
            style={{ backgroundColor: "inherit" ,border:"none"}}
            expanded={expanded === `panel1`}
            onChange={handleChange(`panel1`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                }}
                color={color}
              >
                {keyAdvantages[0].title}
                {expanded && expanded === "panel1" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: keyAdvantages[0].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel2`}
            onChange={handleChange(`panel2`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                }}
                color={color}
              >
                {keyAdvantages[1].title}
                {expanded && expanded === "panel2" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: keyAdvantages[1].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel3`}
            onChange={handleChange(`panel3`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                }}
                color={color}
              >
                {keyAdvantages[2].title}
                {expanded && expanded === "panel3" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: keyAdvantages[2].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel4`}
            onChange={handleChange(`panel4`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                }}
                color={color}
              >
                {keyAdvantages[3].title}
                {expanded && expanded === "panel4" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: keyAdvantages[3].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel5`}
            onChange={handleChange(`panel5`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                }}
                color={color}
              >
                {keyAdvantages[4].title}
                {expanded && expanded === "panel5" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: keyAdvantages[4].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel6`}
            onChange={handleChange(`panel6`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                }}
                color={color}
              >
                {keyAdvantages[5].title}
                {expanded && expanded === "panel6" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: keyAdvantages[5].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel7`}
            onChange={handleChange(`panel7`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel7bh-content"
              id="panel7bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                }}
                color={color}
              >
                {keyAdvantages[6].title}
                {expanded && expanded === "panel7" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: keyAdvantages[6].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={styles["buttons-container"]}>
          <a className={styles[`contact-btn`]} onClick={() => contactUsHref()}>
            CONTACT US
          </a>
        </div>
      </Grid>
      <Grid item xs={6} className={styles["image-container"]}>
        <img className={styles.image} src={currentPanelImage} alt="img" />
      </Grid>
    </Grid>
  );
}
