import React, { useState } from "react";
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  FormLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import "react-phone-number-input/style.css";
import { sendEmail } from "../../actions";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function ContactForm({ color, sendEmail }) {
  const [loading, setLoading] = useState({
    loading: false,
    success: false,
  });

  const [payload, setPayload] = useState({
    system: "sterlingOffice",
    sender: "",
    company: "",
    email: "",
    phoneNumber: "",
    website: "",
    serviceOfInterest: [],
    location: "",
    message: "",
    onSuccess: () => {
      setLoading({
        loading: false,
        success: true,
      });
    },
  });

  const useStyles = makeStyles({
    svg: {
      "&.MuiSvgIcon-root": {
        fill: "#FFFFFF",
      },
    },
    underline: {
      "&:before": {
        borderBottom: `1px solid #000000 !important`,
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: `1px solid #000000`,
      },
      "&:after": {
        borderBottom: `1px solid #000000`,
      },
    },
  });
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
    location: "",
    website: "",
    projectOffice: false,
    servicedOffice: false,
    meetingRooms: false,
    virtualOffices: false,
    flexibleWorkspace: false,
    videoConferencing: false,
    other: false,
    specialRequirements: "",
    agreeToCookies: false,
    agreeToPrivacyPolicy: false,
  });
  const handleChecked = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <div className="contact-form-container">
        <Box component="form">
          <h2
            className="form-heading"
            style={{ marginLeft: "1%", marginTop: "4%" }}
          >
            INQUIRE
          </h2>
          <>
            {!loading.success ? (
              <>
                <Grid
                  container
                  direction={"column"}
                  style={{ marginTop: "-5%" }}
                >
                  <Grid item>
                    <FormControl variant="standard">
                      <InputLabel
                        style={{ color: color }}
                        shrink={false}
                        htmlFor="name"
                      >
                        {payload.sender === "" ? "Name*" : null}
                      </InputLabel>
                      <Input
                        classes={classes}
                        id="name"
                        name="name"
                        value={payload.sender}
                        onChange={(e) => {
                          setPayload({ ...payload, sender: e.target.value });
                        }}
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel
                        shrink={false}
                        style={{ color: color }}
                        htmlFor="company"
                      >
                        {payload.company === "" ? "Company" : null}
                      </InputLabel>
                      <Input
                        classes={classes}
                        id="company"
                        name="company"
                        value={payload.company}
                        onChange={(e) => {
                          setPayload({ ...payload, company: e.target.value });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard">
                      <InputLabel
                        shrink={false}
                        style={{ color: color }}
                        htmlFor="email"
                      >
                        {payload.email === "" ? "Email*" : null}
                      </InputLabel>
                      <Input
                        classes={classes}
                        id="email"
                        name="email"
                        value={payload.email}
                        onChange={(e) => {
                          setPayload({ ...payload, email: e.target.value });
                        }}
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel
                        shrink={false}
                        style={{ color: color }}
                        htmlFor="phone"
                      >
                        {payload.phoneNumber === "" ? "Phone*" : null}
                      </InputLabel>
                      <PhoneInput
                        country={"bg"}
                        value={payload.phoneNumber}
                        onChange={(phone) => {
                          console.log(phone);
                          setPayload({
                            ...payload,
                            phoneNumber: phone,
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard">
                      <InputLabel
                        shrink={false}
                        style={{ color: color }}
                        id="location"
                        htmlFor="location"
                        classes={classes}
                      >
                        {payload.location === "" ? "Select Location" : null}
                      </InputLabel>
                      <Select
                        classes={{
                          root: classes,
                          select: classes.underline,
                          svg: classes.svg,
                        }}
                        sx={{ borderBottom: "1px solid #000000" }}
                        labelId="location"
                        name="location"
                        id="location"
                        value={payload.location}
                        label="Location"
                        onChange={(e) => {
                          setPayload({ ...payload, location: e.target.value });
                        }}
                      >
                        <MenuItem value={"Sofia"}>Sterling Sofia</MenuItem>
                        <MenuItem value={"Istanbul"}>
                          Sterling Istanbul
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel
                        shrink={false}
                        style={{ color: color }}
                        htmlFor="website"
                      >
                        {payload.website === "" ? "Website" : null}
                      </InputLabel>
                      <Input
                        classes={classes}
                        id="website"
                        name="website"
                        value={payload.website}
                        onChange={(e) => {
                          setPayload({ ...payload, website: e.target.value });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <FormLabel style={{ color: color }} component={"legend"}>
                  Service of interest
                </FormLabel>
                <Grid
                  container
                  className="service-container"
                  direction={"column"}
                  spacing={2}
                  style={{ width: "90%" }}
                >
                  <Grid item>
                    <FormControlLabel
                      name="projectOffice"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={payload.serviceOfInterest.includes(
                            "Project Office"
                          )}
                          value="Project Office"
                          onChange={(e) => {
                            if (
                              payload.serviceOfInterest.includes(
                                "Project Office"
                              )
                            ) {
                              const index =
                                payload.serviceOfInterest.indexOf(
                                  "Project Office"
                                );
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.splice(index, 1);
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            } else {
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.push("Project Office");
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            }
                          }}
                        />
                      }
                      label="Project Office"
                    />
                    <FormControlLabel
                      name="servicedOffice"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={payload.serviceOfInterest.includes(
                            "Serviced Office"
                          )}
                          onChange={(e) => {
                            if (
                              payload.serviceOfInterest.includes(
                                "Serviced Office"
                              )
                            ) {
                              const index =
                                payload.serviceOfInterest.indexOf(
                                  "Serviced Office"
                                );
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.splice(index, 1);
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            } else {
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.push("Serviced Office");
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            }
                          }}
                        />
                      }
                      label="Serviced Office"
                    />
                    <FormControlLabel
                      name="meetingRooms"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={payload.serviceOfInterest.includes(
                            "Meeting Rooms"
                          )}
                          onChange={(e) => {
                            if (
                              payload.serviceOfInterest.includes(
                                "Meeting Rooms"
                              )
                            ) {
                              const index =
                                payload.serviceOfInterest.indexOf(
                                  "Meeting Rooms"
                                );
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.splice(index, 1);
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            } else {
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.push("Meeting Rooms");
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            }
                          }}
                        />
                      }
                      label="Meeting Rooms"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      name="virtualOffices"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={payload.serviceOfInterest.includes(
                            "Virtual Offices"
                          )}
                          onChange={(e) => {
                            if (
                              payload.serviceOfInterest.includes(
                                "Virtual Offices"
                              )
                            ) {
                              const index =
                                payload.serviceOfInterest.indexOf(
                                  "Virtual Offices"
                                );
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.splice(index, 1);
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            } else {
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.push("Virtual Offices");
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            }
                          }}
                        />
                      }
                      label="Virtual Offices"
                    />
                    <FormControlLabel
                      name="flexibleWorkspace"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={payload.serviceOfInterest.includes(
                            "Flexible Workspace / Coworking"
                          )}
                          onChange={(e) => {
                            if (
                              payload.serviceOfInterest.includes(
                                "Flexible Workspace / Coworking"
                              )
                            ) {
                              const index = payload.serviceOfInterest.indexOf(
                                "Flexible Workspace / Coworking"
                              );
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.splice(index, 1);
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            } else {
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.push(
                                "Flexible Workspace / Coworking"
                              );
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            }
                          }}
                        />
                      }
                      label="Flexible Workspace / Coworking"
                    />
                    <FormControlLabel
                      name="videoConferencing"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={payload.serviceOfInterest.includes(
                            "Video Conferencing"
                          )}
                          onChange={(e) => {
                            if (
                              payload.serviceOfInterest.includes(
                                "Video Conferencing"
                              )
                            ) {
                              const index =
                                payload.serviceOfInterest.indexOf(
                                  "Video Conferencing"
                                );
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.splice(index, 1);
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            } else {
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.push("Video Conferencing");
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            }
                          }}
                        />
                      }
                      label="Video Conferencing"
                    />
                    <FormControlLabel
                      name="other"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={payload.serviceOfInterest.includes("Other")}
                          onChange={(e) => {
                            if (payload.serviceOfInterest.includes("Other")) {
                              const index =
                                payload.serviceOfInterest.indexOf("Other");
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.splice(index, 1);
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            } else {
                              const newServices =
                                payload.serviceOfInterest.slice(0);
                              newServices.push("Other");
                              setPayload({
                                ...payload,
                                serviceOfInterest: newServices,
                              });
                            }
                          }}
                        />
                      }
                      label="Other"
                    />
                  </Grid>
                </Grid>
                <TextField
                  classes={classes}
                  variant="standard"
                  name="specialRequirements"
                  label="Special Requirements"
                  disabled={
                    payload.serviceOfInterest.includes("Other") ? false : true
                  }
                  onChange={(e) => {
                    setPayload({ ...payload, message: e.target.value });
                  }}
                />
                <div className="form-lower-section">
                  <div className="additional-checkboxes">
                    <FormControlLabel
                      name="agreeToCookies"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={formValues.agreeToCookies}
                          onChange={handleChecked}
                        />
                      }
                      label={
                        <p
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            window.open("/files/Cookies.pdf");
                          }}
                        >
                          Agree to cookies
                        </p>
                      }
                    />
                    <FormControlLabel
                      name="agreeToPrivacyPolicy"
                      control={
                        <Checkbox
                          style={{ color: color }}
                          checked={formValues.agreeToPrivacyPolicy}
                          onChange={handleChecked}
                        />
                      }
                      label={
                        <p
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            window.open("/files/Privacy.pdf");
                          }}
                        >
                          Agree to Privacy Policy
                        </p>
                      }
                    />
                  </div>
                  <NavLink
                    style={{ color: color }}
                    className={"submit-button"}
                    to={"/"}
                    onClick={() => {
                      const newPayload = {
                        ...payload,
                        phoneNumber: `+${payload.phoneNumber}`,
                        serviceOfInterest: payload.serviceOfInterest.join(","),
                      };
                      sendEmail(newPayload);
                    }}
                  >
                    SEND
                  </NavLink>
                </div>
              </>
            ) : (
              <div className="success-container">
                <h3 style={{ fontSize: window.innerWidth < 900 ? "18px" : "22px" }}>
                Thank you! Your message was successfully sent! We will get back to you shortly!
                </h3>
              </div>
            )}
          </>
        </Box>
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});
export default connect(null, mapDispatchToProps)(ContactForm);
