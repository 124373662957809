import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import styles from "./CarouselComponent.module.scss";
import CarouselCard from "./CarouselCard/CarouselCard";

const istanbulServices = [
  {
    image: "/locations-istanbul-section-2-1.png",
    title: "Amenities",
    listItems: [
      "Private Dedicated offices ranging from 2-10 Person",
      "15 offices and 45 workstations ",
      "4 to 12 person Meeting rooms",
      "Sterling Reception Area on Floor 8 ",
      "Welcome Lounge",
      "Club Lounge with Café Area & Kitchen",
      "Freshly brewed coffee, selection of teas, water",
      "24 hour access",
      "Client Services Area - Printer/Photocopier/Scanner/Stationary/Office Equipment",
      "Hefele touchless key access system",
      "Office Building Reception located on Level 1",
      "Building security and CCTV monitoring",
      "Parking "
    ],
  },
  {
    image: "/locations-istanbul-section-2-2.png",
    title: "Services",
    listItems: [
      "Unique telephone numbers, answered in your company name by our Multi-lingual receptionists",
      "CAT6 infrastructure",
      "High speed internet",
      "Wireless network services",
      "Rack space rental",
      "IT specialists",
      "Xerox Workcenter business class copiers and scanners",
      "Multimedia and presentation equipment",
      "Business support",
      "Mail handling",
      "Concierge services",
      "Reception services",
    ],
  }
];

const sofiaServices = [
  {
    image: "/locations-sofia-section-2-1.png",
    title: "Amenities",
    listItems: [
      "138 workstations",
      "27 private office suites (11 with balconies)",
      "From 2 to 21 Person dedicated Offices",
      "7 Meeting, Training & Conference Rooms (from 6-42 persons)",
      "Event/Cocktail venue, Club Lounge, TV Room, Café Areas & Kitchen",
      "Videoconference suite",
      "Freshly brewed coffee, selection of teas, water",
      "Green Wall & Biophilic Design",
      "24 hour access",
      "Server Room",
      "Hefele touchless key access system",
      "Building security and CCTV monitoring",
      "Parking"
    ],
  },
  {
    image: "/locations-sofia-section-2-2.png",
    title: "Services",
    listItems: [
      "Dedicated telephone numbers, answered in your company name",
      "Telephone system with voicemail, call forwarding and message taking",
      "CAT6 internal network",
      "High speed internet and Wi-Fi",
      "VPN and dedicated bandwidth available",
      "Rack space rental",
      "IT support",
      "Presentation equipment",
      "Reception, Courier and Mail handling",
      "Business support",
      "Concierge, Secretarial and Personal Assistance services"
    ],
  }
];

const Services = [
  {
    image: "/services1.png",
    title: "Administrative Support",
    listItems: [
      "Secretarial Services",
      "Admin Services",
      "PA Services",
      "Printing/Copying Services",
      "Phone Answering",
      "Message Taking/Delivery",
      "Mail Handling & Courier Services",
      "Stationary Orders",
      "Office Supplies",
      "Concierge Services",
      "Facsimile Services"
    ],
  },
  {
    image: "/services2.png",
    title: "IT Support",
    listItems: [
      "IT support",
      "Equipment Rental / Purchase",
      "Internet Access",
      "Rack Space",
      "VPN",
      "Phone System",
      "Dedicated Phone Lines",
      "Voice Mail",
      "Disaster Recovery",
    ],
  },
  {
    image: "/services3.png",
    title: "Meeting & Conference",
    listItems: [
      "Events Conference Planning",
      "Event Management",
      "Conference Facilities",
      "Meeting Rooms",
      "Video Conferencing",
      "Training Facilities",
      "Catering",
      "Multimedia",
      "Teleconference",
      "Video Conference",
      "White Board",
      "Parking Area"
    ],
  }
];

export default function CarouselComponent({city}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  return (
    <Grid
      container
      alignItems="center"
      className={styles["carousel-component-container"]}
    >
      {!isMobile && <div style={{display:"flex",flexDirection:"column"}}>
        <h4>OUR SERVICES</h4>
        <h4 className="innerTitle" style={{fontSize:"32px",marginTop:"-8%"}}>PROVIDED FOR YOU</h4>
      </div>}
      {isMobileVersion === 0 && <div className={styles[`carousel-component`]}>
        {isMobile && <h4>OUR SERVICES</h4>}
        {city === "Istanbul" ? 
        istanbulServices.map((service, index) => (
          <CarouselCard
            title={service.title}
            image={service.image}
            listItems={service.listItems}
            key={index}
          />
        )) : city === "Sofia"?  
        sofiaServices.map((service, index) => (
          <CarouselCard
            title={service.title}
            image={service.image}
            listItems={service.listItems}
            key={index}
          />
        ))
        : city === "services" ?
        Services.map((service, index) => (
          <CarouselCard
            title={service.title}
            image={service.image}
            listItems={service.listItems}
            key={index}
          />
        )) : ""}
      </div>
}
{isMobileVersion === 1 ?
 city === "Istanbul" ? <div className={styles[`mobile-carousel-component-bigger`]}>
        {isMobile && <h4 style={{marginLeft:"-10%"}}>OUR SERVICES</h4>}
        {city === "Istanbul" ? 
        istanbulServices.map((service, index) => (
          <CarouselCard
            title={service.title}
            image={service.image}
            listItems={service.listItems}
            key={index}
          />
        )) : city === "Sofia"?  
        sofiaServices.map((service, index) => (
          <CarouselCard
            title={service.title}
            image={service.image}
            listItems={service.listItems}
            key={index}
          />
        ))
        : city === "services" ?
        Services.map((service, index) => (
          <CarouselCard
            title={service.title}
            image={service.image}
            listItems={service.listItems}
            key={index}
          />
        )) : ""}
      </div>
: city === "Sofia" ? <div className={styles[`mobile-carousel-component-bigger`]}>
      {isMobile && <h4 style={{marginLeft:"-10%"}}>OUR SERVICES</h4>}
      {city === "Istanbul" ? 
      istanbulServices.map((service, index) => (
        <CarouselCard
          title={service.title}
          image={service.image}
          listItems={service.listItems}
          key={index}
        />
      )) : city === "Sofia"?  
      sofiaServices.map((service, index) => (
        <CarouselCard
          title={service.title}
          image={service.image}
          listItems={service.listItems}
          key={index}
        />
      ))
      : city === "services" ?
      Services.map((service, index) => (
        <CarouselCard
          title={service.title}
          image={service.image}
          listItems={service.listItems}
          key={index}
        />
      )) : ""}
    </div>
: city === "services" ? <div className={styles[`mobile-carousel-component-bigger`]}>
{isMobile && <h4 style={{marginLeft:"-10%"}}>OUR SERVICES</h4>}
{city === "Istanbul" ? 
istanbulServices.map((service, index) => (
  <CarouselCard
    title={service.title}
    image={service.image}
    listItems={service.listItems}
    key={index}
    height={1100}
  />
)) : city === "Sofia"?  
sofiaServices.map((service, index) => (
  <CarouselCard
    title={service.title}
    image={service.image}
    listItems={service.listItems}
    key={index}
    height={1000}
  />
))
: city === "services" ?
Services.map((service, index) => (
  <CarouselCard
    title={service.title}
    image={service.image}
    listItems={service.listItems}
    key={index}
    different={1}
  />
)) : ""}
</div>
: "" : ""
}
    </Grid>
  );
}
