import React, { useState, useRef } from "react";
import Benefits from "../../components/Benefits/Benefits";
import Card from "../../components/Card/Card";
import Inquiry from "../Inquiry/Inquiry";
import TabsComponent from "../../components/Tabs/Tabs";
import UniqueBusinessEnvironment from "../../components/UniqueBusinessEnvironment/UniqueBusinessEnvironment";
import Gallery from "../../components/Gallery/Gallery";
import { useMediaQuery } from "@mui/material";

const SterlingSofia = [
  "/sofia/1.jpeg",
  "/sofia/2.jpg",
  "/sofia/3.jpg",
  "/sofia/4.jpg",
  "/sofia/5.jpg",
  "/sofia/6.jpg",
  "/sofia/7.jpg",
  "/sofia/8.jpg",
  "/sofia/9.jpg",
  "/sofia/10.jpg",
  "/sofia/11.jpg",
  "/sofia/12.jpg",
  "/sofia/13.jpg",
  "/sofia/14.jpg",
  "/sofia/15.jpg",
  "/sofia/16.jpg",
  "/sofia/17.jpg",
  "/sofia/18.jpg",
  "/sofia/19.jpg",
  "/sofia/20.jpg",
  "/sofia/21.jpg",
  "/sofia/22.jpg",
  "/sofia/23.jpg",
  "/sofia/24.jpg",
  "/sofia/25.jpg",
  "/sofia/26.jpg",
  "/sofia/27.jpg",
  "/sofia/28.jpg",
  "/sofia/29.jpg",
  "/sofia/30.jpg",
  "/sofia/31.jpg",
  "/sofia/32.jpg",
  "/sofia/33.jpg",
  "/sofia/34.jpg",
  "/sofia/35.jpg",
];

const SterlingIstanbul = [
  "/8ppMeeting.jpg",
  "/8ppMeeting2.jpg",
  "/Cafe.jpg",
  "/Cafe2.jpg",
  "/HotDesk.jpg",
  "/HotDesk2.jpg",
  "/HotDesk2a.jpg",
  "/Lobby.jpg",
  "/Office.jpg",
  "/Office2.jpg",
  "/OfficeSuite.jpg",
  "/Reception.jpg",
  "/Reception2.jpg",
  "/Recep-VCrm.jpg",
  "/ServicesArea.jpg",
  "/VCRoom.jpg",
  "/VCRoom2.jpg",
  "/VCRoom2a.jpg",
];


export default function Why() {
  const contactUs = useRef(null);
  const [currentImageSet, setCurrentImageSet] = useState(SterlingSofia);
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <>
      <Benefits contactUs={contactUs} />
      <Card
        direction={"left"}
        backgroundColor={"#a0a0a2"}
        title={"First Class Services"}
        list={[]}
        description={`Your Office at Sterling – creating Great Corporate Image for your company.

        We manage everything and let you focus on your business and success.
        
        We provide solutions and alternatives that best fit your specific requirements.
        
        Our Sterling Team – eager to provide you with prompt and professional service.`}
        sideText={`The best you can get`}
        hasLearnMoreBtn={false}
        hasContactBtn={true}
        image={"/why-4-2.png"}
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <TabsComponent
        backgroundColor={"#FFFFFF"}
        sideText={"The professional way"}
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
        istanbulText={`Sterling Istanbul is located on 8th, 9th and 10th floors in the prestigious twin tower, 27 floor Astoria complex.

        Astoria has everything you need for business and leisure in one location.
        
        Towers managed by Kempinski Hotels, a fitness club, Astoria Shopping Centre with brand stores, supermarket, restaurants and cafes, and in-door parking for 800 cars.`}

        sofiaText={`Sterling at Serdika offers the advantage of city center closeness – next to Oborishte Street, within minutes from the heart of Sofia and just 15 minutes drive to the Sofia International Airport. There are 5 bus and 3 tram lines, as well as an office shuttle to & from the nearest metro station at Eagles Bridge.

        Located on top floor, clients can enjoy the spectacular views of Sofia and Vitosha Mountain and access to the popular Serdika Shopping Center with over 200 shops, cafes, restaurants, bank services as well as ample parking.
        
        This outstanding property has Gold DGNB certificate for sustainable building and assures: highly efficient motorized sun protection, double-skin facade with openable windows, plenty of natural light to reach any place in your office, ventilation system for fresh air supply with temperature regulation twice per hour.`}
      />
      <UniqueBusinessEnvironment />
      {isMobile ? (
        <>
          <Gallery
            hasOptions={false}
            currentImageSet={SterlingSofia}
            locationHeading={"STERLING SOFIA"}
          />
          <Gallery
            hasOptions={false}
            currentImageSet={SterlingIstanbul}
            locationHeading={"STERLING ISTANBUL"}
            hasTitle={false}
          />
        </>
      ) : (
        <Gallery
          hasOptions={true}
          currentImageSet={currentImageSet}
          setCurrentImageSet={setCurrentImageSet}
          SterlingIstanbul={SterlingIstanbul}
          SterlingSofia={SterlingSofia}
        />
      )}
      <div ref={contactUs}>
        <Inquiry backgroundColor={"#FFFFFF"} direction="right" city={"Sofia"}/>
      </div>
    </>
  );
}
