import React, { useRef } from "react";
import Card from "../../../components/Card/Card";
import Gallery from "../../../components/Gallery/Gallery";
import CarouselComponent from "../../../components/CarouselComponent/CarouselComponent";
import IndividualLocation from "../../../components/IndividualLocation/InvidiualLocation";
import Inquiry from "../../Inquiry/Inquiry";
import "./styles.scss";

const location = [
  {
    address: "127 Büyükdere Cad. 34394, Floor 8, Astoria, Istanbul, Turkey",
    phone: "+90 (0)212 340 7600 | +90 (0)212 340 7601 ",
    email: "istanbul@sterlingoffice.com",
    image: "/sterling-istanbul.png",
    name: "Sterling Istanbul",
    location: { lat: 41.07620331571032, lng: 29.012647482482105 },
  },
];

const SterlingIstanbul = [
  "/8ppMeeting.jpg",
  "/8ppMeeting2.jpg",
  "/Cafe.jpg",
  "/Cafe2.jpg",
  "/HotDesk.jpg",
  "/HotDesk2.jpg",
  "/HotDesk2a.jpg",
  "/Office.jpg",
  "/Office2.jpg",
  "/OfficeSuite.jpg",
  "/Reception.jpg",
  "/Reception2.jpg",
  "/Recep-VCrm.jpg",
  "/ServicesArea.jpg",
  "/VCRoom.jpg",
  "/VCRoom2.jpg",
  "/VCRoom2a.jpg",
];

export default function IstanbulLocation() {
  const contactUs = useRef(null);
  return (
    <>
      <Card
        title={"Sterling Istanbul"}
        description={`Sterling Istanbul is located on 8th, floor in the prestigious twin tower, 27 floor Astoria complex.

        Astoria, centrally located in Esentepe, on Buyukdere Caddesi, the heart of Istanbul’s modern business district, has everything you need for business and leisure in one location. 
        
        Towers managed by Kempinski Hotels, a fitness club, Astoria Shopping Centre with brand stores, supermarket, restaurants and cafés, and in-door parking for 800 cars.`}
        image={"/sterling-istanbul.png"}
        hasContactBtn={true}
        hasLearnMoreBtn={true}
        learnMoreBtnText="VIDEO TOUR"
        learnMoreHref="https://www.youtube.com/watch?v=p5mrP-wOpno"
        sideText={`About the location`}
        backgroundColor={"#FFFFFF"}
        direction={"right"}
        list={[]}
        contactUsHref={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <CarouselComponent city={"Istanbul"}/>
      <Gallery hasOptions={false} currentImageSet={SterlingIstanbul} />
      <IndividualLocation location={location} />
      <div ref={contactUs}>
        <Inquiry direction="right" backgroundColor={"#FFFFFF"} city={"Istanbul"}/>
      </div>
    </>
  );
}
