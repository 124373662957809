import React, { useState } from "react";
import {
  Accordion,
  Grid,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import styles from "./UniqueBusinessEnvironment.module.scss";
import { find } from "lodash";
import { NavLink } from "react-router-dom";

const businessEnv = [
  {
    title: `PREMIUM DESIGN & FIT OUT`,
    id: "panel1",
    image: "/why-4-1.png",
    description: `
      <ul>
        <li>
        High Quality Italian Furniture
        </li>
        <li>
        Finishes in wood veneers and stone
        </li>
        <li>
        Custom-Made décor accessories
        </li>
        <li>
        Natural Green Wall
        </li>
        <li>
        Various Facilities including club lounge, café area, client services areas
        </li>
        <li>
        Modern Reception and Welcome Areas – represented by our Client Services Associates
        </li>
        <li>
        Café Areas equipped with modern Coffee Machines, Refrigerators
        </li>
        <li>
        Freshly brewed Coffees, Selection of Teas, Filtered Water Fruits
        </li>
    </ul>
      `,
  },
  {
    id: "panel2",
    title: "STATE OF THE ART TECHNOLOGY/IT/TELECOM",
    image: "/state-of-art.png",
    description: `
      <ul>
      <li>
      Server rooms - with Cisco Systems equipment providing you with ability for rack space, VPN, Dedicated Bandwidth, etc.
      </li>
      <li>
      Avaya & Cisco  Phone Systems
      </li>
      <li>
      VideoConferencing Equipment
      </li>
      <li>
      TeleConferencing Equipment
      </li>
      <li>
      Multimedia & TV; LCD Projectors & Screens
      </li>
      <li>
      Client Services Areas on each floor
      </li>
      <li>
      Multifunctional Printer/Copiers/Scanners/Fax
      </li>
      <li>
      Binders
      </li>
      <li>
      Shredders
      </li>
      <li>
      Stationery
      </li>
      <li>
      Hafele Touchless Key System for all office and entrance doors
      </li>
    </ul>
      `,
  },
  {
    id: "panel3",
    title: "OUR EXPERIENCE/ OUR MISSION",
    image: "/why-4-3.png",
    description: `
     <ul>
     <li>
     We understand Client Needs
     </li>
     <li>
     We care about their success and we want to try and help them succeed
     </li>
     <li>
     We value long term collaboration and loyalty
     </li>
     <li>
     We want to ensure our clients feel pleasant,healthy and productive within their workspace
     </li>
   </ul>
     `,
  }
];

export default function UniqueBusinessEnvironment({
  backgroundColor = "#a0a0a2",
  color = "#FFFFFF",
}) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [expanded, setExpanded] = useState("panel1");
  const [currentPanelImage, setCurrentPanelImage] = useState(
    businessEnv[0].image
  );
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setCurrentPanelImage(find(businessEnv, { id: panel }).image);
  };
  return Boolean(isMobile) ? (
    <>
      <Grid
        className={styles["mobile-environments"]}
        container
        justifyContent={"center"}
      >
        
        <h4>UNIQUE BUSINESS ENVIRONMENTS</h4>
        <div className={styles[`horizontal-text-container`]}>
          <p style={{fontSize:"18px",textTransform:"uppercase"}}>18+ years experience</p>
        </div>
        {businessEnv.map((environment, index) => (
          <div className={styles["mobile-environments-container"]} key={index}>
            <img
              className={styles.image}
              src={environment.image}
              alt="office-image"
            />
            <p className={styles["environment-title"]} style={{marginBottom:"-6%",marginTop:"3%"}}>{environment.title}</p>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: environment.description }}
            ></div>
          </div>
        ))}
      </Grid>
    </>
  ) : (
    <Grid
      container
      alignItems="center"
      style={{
        backgroundColor: backgroundColor,
        color: backgroundColor === "#FFFFFF" ? "#181E24" : "#FFFFFF",
      }}
      className={styles[`home-container`]}
    >
      <Grid item xs={6} className={styles["info-container"]} style={{height:window.innerWidth < 1300 && "110vh"}}>
        <h2 className={styles.title}  style={{marginTop:"0%",paddingTop:"10px"}}>Unique Business Environments</h2>
        <h2 className={styles.innerTitle} style={{paddingBottom:"10px"}}>18+ years experience</h2>
        <div className={styles["key-advantages"]}>
          {" "}
          <Accordion
            style={{ backgroundColor: "inherit",color:"white" }}
            expanded={expanded === `panel1`}
            onChange={handleChange(`panel1`)}
            elevation={0}
          >
            <AccordionSummary
              color={"white"}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                  whiteSpace: "pre-line",
                }}
                color={color}
              >
                {businessEnv[0].title}
                {expanded && expanded === "panel1" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: businessEnv[0].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel2`}
            onChange={handleChange(`panel2`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                  // whiteSpace: "pre-line",
                }}
                color={color}
              >
                {businessEnv[1].title}
                {expanded && expanded === "panel2" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: businessEnv[1].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "inherit" }}
            expanded={expanded === `panel3`}
            onChange={handleChange(`panel3`)}
            elevation={0}
          >
            <AccordionSummary
              color={color}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                variant="subtitle1"
                display={"flex"}
                whiteSpace={"nowrap"}
                alignItems={"center"}
                style={{
                  width: "100%",
                  // whiteSpace: "pre-line",
                }}
                color={color}
              >
                {businessEnv[2].title}
                {expanded && expanded === "panel3" && (
                  <hr style={{ width: "100%", paddingRight: "10px" }} />
                )}
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: businessEnv[2].description,
                }}
                style={{ color: color }}
                className={styles.description}
              ></div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className={styles["buttons-container"]}>
          <NavLink className={styles[`contact-btn`]} to={"/"}>
            CONTACT US
          </NavLink>
        </div>
      </Grid>
      <Grid item xs={6} className={styles["image-container"]} style={{height:"100%"}}>
        <img className={styles.image} src={currentPanelImage} alt="img" />
      </Grid>
    </Grid>
  );
}
