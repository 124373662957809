import { Grid, Divider } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";
import { useMediaQuery } from "@mui/material";

function TakeALook() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <Grid container className="take-a-look-container">
      <Grid item xs={12} lg={6} className="sterling-sofia-container">
        <video className="video-background" autoPlay playsInline loop muted>
          <source type="video/mp4" src={"/videos/sterling-sofia-video.mov"} />
        </video>
        <div className="take-a-look-text">
          <h2>Sterling Sofia</h2>
        </div>
        <div className="take-a-look-button">
          <a href="https://www.youtube.com/watch?v=3vepixlqXas">Take Me To Sterling Sofia</a>
        </div>
      </Grid>
      {isMobile && <Divider style={{ backgroundColor: "white" }} />}
      <Grid item xs={12} lg={6} className="sterling-istanbul-container">
        <video className="video-background" autoPlay playsInline loop muted>
          <source type="video/mp4" src={"/videos/sterling-turkey-video.mp4"} />
        </video>
        <div className="take-a-look-text">
          <h2>Sterling Istanbul</h2>
        </div>
        <div className="take-a-look-button">
          <a href="https://www.youtube.com/watch?v=p5mrP-wOpno">
            Take Me To Sterling Istanbul
          </a>
        </div>
      </Grid>
    </Grid>
  );
}
export default TakeALook;
