import React, { useState } from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import styles from "./IndividualLocation.module.scss";
import { useMediaQuery } from "@mui/material";
import MobileLocations from "../MobileLocations/MobileLocations";
import GoogleMaps from "../GoogleMaps/GoogleMaps";

export default function IndividualLocation({ location }) {
  const individualLocation = location[0];
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [media, setMedia] = useState("map");
  return Boolean(isMobile) ? (
    <MobileLocations locations={location} />
  ) : (
    <Grid
      className={styles["locations-container"]}
      container
      alignItems={"stretch"}
    >
      <Grid item xs={6} className={styles["locations-info-container"]}>
        <Grid container>
          <Grid item xs={11} className={styles["locations-tabs-container"]}>
            <h2 className={styles.title}>LOCATION</h2>
            <h2 className={styles.innerTitle}>Mark the spot</h2>
            <Typography variant="subtitle2">ADDRESS</Typography>
            <Typography variant="body1" style={{marginBottom:"5%"}}>
              {individualLocation.address}
            </Typography>
            <Typography variant="subtitle2">PHONE</Typography>
            <Typography variant="body1" style={{marginBottom:"5%"}}>{individualLocation.phone}</Typography>
            <Typography variant="subtitle2">EMAIL</Typography>
            <Typography variant="body1">{individualLocation.email}</Typography>
          </Grid>
          <Grid item xs={1} style={{ borderRight: "1px solid white" }}>
            <Stack
              divider={<Divider flexItem />}
              spacing={1}
              className={styles["options-container"]}
            >
              <img
                src="/location-icon.png"
                alt="location-icon"
                onClick={() => setMedia("map")}
              />
              <img
                src="/media-icon.png"
                alt="media-icon"
                onClick={() => setMedia("image")}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} className={styles["image-container"]}>
        {media === "map" ? (
          <GoogleMaps
            address={individualLocation.name}
            location={individualLocation.location}
          />
        ) : (
          <img
            src={individualLocation.image}
            alt="building"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Grid>
    </Grid>
  );
}
