import { createStore, applyMiddleware,compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import reducers from "../reducers";

import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "../epics";

const createHistory = require("history").createBrowserHistory
const history = createHistory()
const epicMiddleware = createEpicMiddleware()

const middleware = []
middleware.push(routerMiddleware(history))
middleware.push(epicMiddleware)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore)

const store = createStoreWithMiddleware(reducers)
epicMiddleware.run(rootEpic)

export { store, history }
